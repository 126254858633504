import { DetailService } from './../detail.service';
import { SqliteService } from './../sqlite.service';
import { Router } from '@angular/router';
import { ToastController, AlertController, Platform } from '@ionic/angular';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { BleClient, dataViewToNumbers, numberToUUID, BluetoothLe, dataViewToHexString } from '@capacitor-community/bluetooth-le';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
// import { Missione } from './missione.model';
import { AuthService } from '../auth/auth.service';
import { map, tap, take } from 'rxjs/operators';
import { Geolocation, Position } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
// import { BLE } from '@ionic-native/ble/ngx';
// import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { 
    MissioneModel,
    AutomezzoBaseModel,
    AutomezzoIdentifierModel,
    ArticoloIdentifierModel, 
    ItinerarioBaseModel,
    ItinerarioIdentifierModel,
    ArticoloBaseModel, PdiIdentifierModel,
    PdiBaseModel,
    RichiestaProdottiBaseModel,
    PrenotazioneIdentifierModel,
    PrenotazioneModel,
    ItinerarioModel,
    RichiestaModel,
    RichiestaIdentifierModel,
    RichiestaProdottiIdentifier,
    RichiestaProdottiModel,
    CausaleTrasportiIdentifierModel,
    CausaleTrasportiBaseModel,
    RichiestaBaseModel,
    eTipoOperazioneItinerario,
    eStatoRichiesta,
    TipoServizio,
    CoordinateModel
} from './missione-itinerario.model';
import { environment } from './../../environments/environment.prod';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';

//import { ForegroundService } from '@ionic-native/foreground-service/ngx';

interface DeviceTemp{
    id_assegnazione: string;
    id_device: string;
    temp: string;
    date: string;
    time: string;
}

interface Automezzo {
    Result: boolean,
    Automezzi: AutomezzoBaseModel[]
}

export interface Missioni{
    Result: boolean;
    Missioni: MissioneModel[];
}

interface Missione{
    Result: boolean;
    Missione: MissioneModel;
}

interface resultSetStatoMissione{
    Result: boolean;
}

@Injectable({
    providedIn: 'root'
})

export class MissioniService {
    private _missioni = new BehaviorSubject<MissioneModel[]>([]);
    private _missione = new BehaviorSubject<MissioneModel>(null);
    private _max = new BehaviorSubject<any>(null);

    private bluSensorService = numberToUUID(Number(0xaa20));
    private bluSensorCharacteristic = numberToUUID(Number(0XAA21));
   /*  private bluSensorService = 'a8a82630-10a4-11e3-ab8c-f23c91aec05e';
    private bluSensorCharacteristic = 'a8a82631-10a4-11e3-ab8c-f23c91aec05e'; */
    private device_temp : DeviceTemp;
    private azienda: string = localStorage.getItem('azienda');

    private api_url : string = '';

    automezzi: AutomezzoBaseModel[] = [];

    get missioni() {
        // eslint-disable-next-line no-underscore-dangle
        return this._missioni.asObservable();
    }

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private permissions: AndroidPermissions, 
        private toastCtrl: ToastController,
        //private ble: BLE,
        private alertCtrl: AlertController,
        private router: Router,
        private platform: Platform,
        private sqliteService: SqliteService,
        private detailService: DetailService
        //private foregroundService: ForegroundService,
        //private backgrounMode: BackgroundMode
    ) { }

    fetchMissioniWeb(targa: string) {
        console.log('fetchmissioniweb');
        let utente: any;
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            utente = localStorage.getItem('utente');
        }else{
            this.authService.username.subscribe(username => {
                utente = username;
            });
        }

        //MODIFICARE MANUALMENTE PER TESTARE LE MISSIONI
        //utente = 'MORZILLO';
        let tg = targa;
        localStorage.setItem('targa', tg);

        let date = new Date();
        let anno = date.getFullYear();
        let mese;
        let giorno;
        if ((+date.getMonth() + 1) < 10){
            mese = '0' + (date.getMonth() + 1).toString();
        }else{
            mese = (date.getMonth() + 1).toString();
        }
        if (+date.getDate() < 10){
            giorno = '0' + +date.getDate();
        }else{
            giorno= +date.getDate();
        }
        
        let data = anno.toString() + mese + giorno.toString();
        
        console.log('data', data);
        console.log('targa', tg);
        let azienda: string;
        if( localStorage.getItem('azienda') ){
            azienda = localStorage.getItem('azienda');
        }
        
        console.log('azienda', azienda);
        switch(azienda)
        {
        case 'svs':
            this.api_url = environment.api_url_svs;
            break;
        case 'cts':
            this.api_url = environment.api_url_cts;
            break;  
        }
        console.log('api url', this.api_url+'Missione/GetList');
        return this.http.post<Missioni>(this.api_url+'Missione/GetList',
                            { MezzoIdentifier: { Targa: tg }, Data: data })
        .pipe( 
            map( resData => {
            console.log('missioni fetc', JSON.stringify(resData));
                const missioni : MissioneModel[] = [];
                if( resData.Result  )
                {
                    console.log('resdata lista missioni', resData);
                    resData.Missioni.forEach(element => {

                        let PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                            element.Prenotazione.Identifier.Esercizio,
                            element.Prenotazione.Identifier.Numero,
                            element.Prenotazione.Identifier.NumeroRigaMissione,
                            element.Prenotazione.Identifier.Registro
                        )

                        let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                            element.Prenotazione.Automezzo.Identifier.Targa
                        )

                        let PrenotazioneAutomezzo = new AutomezzoBaseModel(
                            PrenotazioneAutomezzoIdentifier,
                            element.Prenotazione.Automezzo.Sigla
                        )

                        let ItinerariPrenotazione: ItinerarioModel[] = [];

                        element.Prenotazione.Itinerari.forEach( item => {
                            let RichiesteProdotti: RichiestaProdottiModel[] = [];

                            let ItinerarioIdentifier = new ItinerarioIdentifierModel(
                                item.Identifier.Esercizio,
                                item.Identifier.Registro,
                                item.Identifier.Numero,
                                item.Identifier.NumeroRigaItinerario
                            )

                            let ItinerarioCausale : CausaleTrasportiIdentifierModel;
                            if( item.CausaleIdentifier )
                            {
                                ItinerarioCausale = new CausaleTrasportiIdentifierModel(
                                    item.CausaleIdentifier.Codice
                                )
                            }
                            
                            let ItinerarioArticoloIdentifier = new ArticoloIdentifierModel(
                                item.Articolo.Identifier.Codice
                            )

                            let ItinerarioArticolo = new ArticoloBaseModel(
                                ItinerarioArticoloIdentifier,
                                item.Articolo.Flann,
                                item.Articolo.Attivo,
                                item.Articolo.Barcode,
                                item.Articolo.Descrizione,
                                item.Articolo.DescrizioneAggiuntiva
                            )

                            let ItinerarioPdiArrivo: PdiBaseModel;     
                        
                            let ItinerarioPdiPartenza: PdiBaseModel;

                            let ItinerarioOrarioPartenza: string = '';

                            let ItinerarioOrarioArrivo: string = '';

                            if( item.TipoOperazione === 1 )
                            {
                                ItinerarioOrarioPartenza = item.OrarioPartenza;

                                let ItinerarioPdiPartenzaCoordinate : CoordinateModel;
                                if( item.PdiPartenza.Coordinate )
                                {
                                    ItinerarioPdiPartenzaCoordinate = new CoordinateModel(
                                        item.PdiPartenza.Coordinate.Latitudine,
                                        item.PdiPartenza.Coordinate.Longitudine
                                    )
                                }
                                
                                let ItinerarioPdiPartenzaIdentifier = new PdiIdentifierModel(
                                    item.PdiPartenza.Identifier.Codice
                                )

                                ItinerarioPdiPartenza = new PdiBaseModel(
                                    ItinerarioPdiPartenzaIdentifier,
                                    item.PdiPartenza.Descrizione1,
                                    item.PdiPartenza.Descrizione2,
                                    item.PdiPartenza.Descrizione3,
                                    ItinerarioPdiPartenzaCoordinate
                                )
                                ItinerarioPdiArrivo = null;

                            }        
                            if( item.TipoOperazione === 2 )
                            {
                                ItinerarioOrarioArrivo = item.OrarioArrivo;

                                let ItinerarioPdiArrivoCoordinate : CoordinateModel;
                                if( item.PdiArrivo.Coordinate )
                                {
                                    ItinerarioPdiArrivoCoordinate = new CoordinateModel(
                                        item.PdiArrivo.Coordinate.Latitudine,
                                        item.PdiArrivo.Coordinate.Longitudine
                                    )
                                }
                                
                                let ItinerarioPdiArrivoIdentifier = new PdiIdentifierModel(
                                    item.PdiArrivo.Identifier.Codice
                                )

                                ItinerarioPdiArrivo = new PdiBaseModel(
                                    ItinerarioPdiArrivoIdentifier,
                                    item.PdiArrivo.Descrizione1,
                                    item.PdiArrivo.Descrizione2,
                                    item.PdiArrivo.Descrizione3,
                                    ItinerarioPdiArrivoCoordinate
                                )
                                ItinerarioPdiPartenza = null;

                            }

                            item.RichiesteProdotti.forEach(rich => {
                                let RichiestaProdIdentifier = new RichiestaProdottiIdentifier(
                                    rich.Identifier.Esercizio,
                                    rich.Identifier.Numero,
                                    rich.Identifier.NumeroRigaItinerario,
                                    rich.Identifier.NumeroRigaMissione,
                                    rich.Identifier.Registro,
                                    rich.Identifier.NumeroRigaStato
                                )

                                let RichiestaProd = new RichiestaProdottiModel (
                                    RichiestaProdIdentifier,
                                    rich.StatoRichiesta,
                                    rich.DataComunicazione,
                                    rich.OraComunicazione,
                                    rich.Coordinate
                                )

                                RichiesteProdotti.push(RichiestaProd);    
                            })
                            
                            let Itinerario = new ItinerarioModel(
                                ItinerarioIdentifier,
                                item.Annotazioni,
                                ItinerarioOrarioPartenza,
                                ItinerarioOrarioArrivo,
                                item.Quantita,
                                ItinerarioCausale,
                                item.TipoOperazione,
                                ItinerarioArticolo,
                                ItinerarioPdiPartenza,
                                ItinerarioPdiArrivo,
                                RichiesteProdotti
                            )

                            ItinerariPrenotazione.push(Itinerario);
                        })

                        let color_background: string;
                        let color_siren: string;
                        let color_border: string;
                        let arrow_detail: string = 'true';

                        if( element.Prenotazione.StatoRichiesta === 6 || element.Prenotazione.StatoRichiesta === 8)
                        {
                            color_background = 'success';
                            color_siren = 'ordinario_bianco';
                        }
                        if( element.Prenotazione.StatoRichiesta === 7)
                        {
                            color_background = 'warning';
                            color_siren = "ordinario";
                        }
                        if( element.Prenotazione.StatoRichiesta === 4 )
                        {
                            color_border = 'border-item-close';
                            arrow_detail = 'false';
                        }
                        
                        let Prenotazione = new PrenotazioneModel(
                            PrenotazioneIdentifier,
                            element.Prenotazione.Assegnazione,
                            element.Prenotazione.DataViaggio,
                            element.Prenotazione.OraViaggio,
                            element.Prenotazione.StatoRichiesta,
                            color_background,
                            color_siren,
                            color_border,
                            arrow_detail,
                            PrenotazioneAutomezzo,
                            ItinerariPrenotazione
                        )

                        let RichiestaIdentifier = new RichiestaIdentifierModel(
                            element.Richiesta.Identifier.Esercizio,
                            element.Richiesta.Identifier.Registro,
                            element.Richiesta.Identifier.Numero
                        )

                        let RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                            element.Richiesta.Causale.Identifier.Codice
                        )

                        let RichiestaCausale = new CausaleTrasportiBaseModel(
                            RichiestaCausaleIdentifier,
                            element.Richiesta.Causale.Descrizione
                        )

                        let Richiesta = new RichiestaBaseModel(
                            RichiestaIdentifier,
                            element.Richiesta.TipoServizio,
                            RichiestaCausale,
                            element.Richiesta.Descrizione
                        )

                        let Missione = new MissioneModel(
                            Prenotazione,
                            Richiesta
                        )

                        missioni.push(Missione);

                    });
                    
                }   
                else
                {
                    //Errore
                } 
                console.log('missioni fetch web', missioni);
                return missioni;   
            }), 
        tap( missions => {
                this._missioni.next(missions);
            })    
        );  
    }   

    /* async fetchMissioni(targa: string):Promise<MissioneModel[]> {
        console.log('fetchmissioni');
        let utente: any;
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            utente = localStorage.getItem('utente');
        }else{
            this.authService.username.subscribe(username => {
                utente = username;
            });
        }
        
        let tg = targa;
        localStorage.setItem('targa', tg);

        //Lettura dei dati dal database locale
        let query : string = '';
        let res: any;

        const Missioni : MissioneModel[] = [];
        let db: SQLiteDBConnection;
        try 
        {
            db = await this.SetConnection();

            query = `SELECT T.*, p.numero_riga, p.data_viaggio, p.ora_viaggio, p.stato_richiesta, p.id_automezzo FROM testata AS t INNER JOIN prenotazioni AS p on p.esercizio=t.esercizio and p.registro=t.registro and p.numero=t.numero WHERE t.id_automezzo='${tg}' ORDER BY p.ora_viaggio`;
            console.log('query select testata + prenotazioni', query);
            res = await db.query(query);
            console.log(`res della select testata + prenotazioni: ${JSON.stringify(res)}`)
            
            console.log('risultato',JSON.stringify(res.values));

            //Aggiungere i controlli per verificare se ci sono dati TODO!!!!
            if( JSON.stringify(res.values).length > 0 )
            {
                let pdi_partenza = 0;
                let pdi_destinazione = 0;
                let esercizio = '';
                let registro = '';
                let numero = 0;
                let id_assegnazione = '';
                let data_viaggio = '';
                let ora_viaggio = '';
                let stato_richiesta = 0;
                let descrizione = '';
                let tipo_servizio = 901;
                let causale ='';
                let causale_descr = '';
                let PrenotazioneAutomezzo: AutomezzoBaseModel;
                let PrenotazioneIdentifier: PrenotazioneIdentifierModel;

                let prev_numero: number =  0;
                let Missione: MissioneModel;

                res.values.forEach( item => {

                    if( item.numero !== prev_numero || prev_numero === 0 )
                    {
                        console.log('1');
                        console.log('item',JSON.stringify(item));
                        pdi_partenza = item.pdi_partenza;
                        console.log('pdi_partenza', pdi_partenza);
                        pdi_destinazione = item.pdi_destinazione;
                        esercizio = item.esercizio;
                        registro = item.registro;
                        numero = item.numero;
                        id_assegnazione =item.id_assegnazione;
                        data_viaggio = item.data_viaggio;
                        ora_viaggio = item.ora_viaggio;
                        stato_richiesta = item.stato_richiesta;
                        descrizione = item.descrizione;
                        tipo_servizio = item.id_tipomezzo;
                        causale = item.causale;    
                        causale_descr = item.causale_descr;

                        PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                            esercizio,
                            numero,
                            parseInt(item.numero_riga),
                            registro
                        ) 
                        
                        let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                            item.id_automezzo
                        )
    
                        PrenotazioneAutomezzo = new AutomezzoBaseModel(
                            PrenotazioneAutomezzoIdentifier,
                            ''
                        )

                        prev_numero = numero;
                    }        
                    console.log('2');
                    console.log('tipo servizio', tipo_servizio);
                    let ItinerariPrenotazione: ItinerarioModel[] = [];   
                    
                    let color_background: string;
                    let color_siren: string;
                    let color_border: string = '';
                    let arrow_detail: string = 'true';

                    if( stato_richiesta === 6 || stato_richiesta === 8)
                    {
                        color_background = 'success';
                        color_siren = 'ordinario_bianco';
                    }
                    if( stato_richiesta === 7)
                    {
                        color_background = 'warning';
                        color_siren = 'ordinario';
                    }
                    if( stato_richiesta === 4 )
                    {
                        color_border = 'border-item-close';
                        arrow_detail = 'false';
                    }
                    
                    let Prenotazione = new PrenotazioneModel(
                        PrenotazioneIdentifier,
                        id_assegnazione,
                        data_viaggio,
                        ora_viaggio,
                        stato_richiesta,
                        color_background,
                        color_siren,
                        color_border,
                        arrow_detail,
                        PrenotazioneAutomezzo,
                        ItinerariPrenotazione
                    )                

                    let RichiestaIdentifier = new RichiestaIdentifierModel(
                        esercizio,
                        registro,
                        numero
                    )

                    let RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                        causale
                    )

                    let RichiestaCausale = new CausaleTrasportiBaseModel(
                        RichiestaCausaleIdentifier,
                        causale_descr
                    )

                    let Richiesta = new RichiestaBaseModel(
                        RichiestaIdentifier,
                        tipo_servizio,
                        RichiestaCausale,
                        descrizione
                    )

                    Missione = new MissioneModel(
                        Prenotazione,
                        Richiesta
                    )

                    console.log('missione nuova', JSON.stringify(Missione));
                    Missioni.push(Missione);
                }); 
                
                console.log('missioni nuove',JSON.stringify(Missioni));
                this._missioni.next(Missioni);

                // await db.close();
                /* await this.sqliteService.closeConnection('missioni');
                console.log(`after db.close`) */

               /*  return Missioni;
            }
        }
        catch{
            //errore
        }
    } */ 

    refreshMissioni() {
        console.log('refresh missioni');
        let utente: any;
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            utente = localStorage.getItem('utente');
        } else {
            this.authService.username.subscribe(username => {
                utente = username;
            });
        }

        let tg = localStorage.getItem('targa');
        let date = new Date();
        let anno = date.getFullYear();
        let mese: string;
        let giorno;
        if ((+date.getMonth() + 1) < 10){
            mese = '0' + (date.getMonth() + 1).toString();
        }else{
            mese = (date.getMonth() + 1).toString();
        }
        if (+date.getDate() < 10) {
            giorno = '0' + +date.getDate();
        } else {
            giorno = +date.getDate();
        }
        let data = anno.toString() + mese + giorno.toString();

        switch(this.azienda)
        {
        case 'svs':
            this.api_url = environment.api_url_svs;
            break;
        case 'cts':
            this.api_url = environment.api_url_cts;
            break;
        }
        console.log('api url', this.api_url+'Missione/GetList');
        return this.http.post<Missioni>(this.api_url+'Missione/GetList',
                            { MezzoIdentifier: { Targa: tg }, Data: data })
        .pipe( 
            map( resData => {
                console.log('resData api', JSON.stringify(resData));
                
                const missioni : MissioneModel[] = [];
                if( resData.Result  )
                {
                    resData.Missioni.forEach(element => {
                        console.log('element', element);

                        let PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                            element.Prenotazione.Identifier.Esercizio,
                            element.Prenotazione.Identifier.Numero,
                            element.Prenotazione.Identifier.NumeroRigaMissione,
                            element.Prenotazione.Identifier.Registro
                        )
  
                        let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                            element.Prenotazione.Automezzo.Identifier.Targa
                        )

                        let PrenotazioneAutomezzo = new AutomezzoBaseModel(
                            PrenotazioneAutomezzoIdentifier,
                            element.Prenotazione.Automezzo.Sigla
                        )

                        let ItinerariPrenotazione: ItinerarioModel[] = [];

                        let RichiesteProdotti: RichiestaProdottiModel[] = [];
    
                        element.Prenotazione.Itinerari.forEach( item => {

                            let ItinerarioIdentifier = new ItinerarioIdentifierModel(
                                item.Identifier.Esercizio,
                                item.Identifier.Registro,
                                item.Identifier.Numero,
                                item.Identifier.NumeroRigaItinerario
                            )

                            let ItinerarioCausale : CausaleTrasportiIdentifierModel;
                            if( item.CausaleIdentifier )
                            {
                                ItinerarioCausale = new CausaleTrasportiIdentifierModel(
                                    item.CausaleIdentifier.Codice
                                )
                            }
                            else
                            {
                                ItinerarioCausale = null;
                            }
                            
                            let ItinerarioArticoloIdentifier = new ArticoloIdentifierModel(
                                item.Articolo.Identifier.Codice
                            )
 
                            let ItinerarioArticolo = new ArticoloBaseModel(
                                ItinerarioArticoloIdentifier,
                                item.Articolo.Flann,
                                item.Articolo.Attivo,
                                item.Articolo.Barcode,
                                item.Articolo.Descrizione,
                                item.Articolo.DescrizioneAggiuntiva
                            )

                            let ItinerarioPdiArrivo: PdiBaseModel;     
                     
                            let ItinerarioPdiPartenza: PdiBaseModel;

                            let ItinerarioOrarioPartenza: string = '';
 
                            let ItinerarioOrarioArrivo: string = '';
 
                            if( item.TipoOperazione === 1 )
                            {
                                ItinerarioOrarioPartenza = item.OrarioPartenza;

                                let ItinerarioPdiPartenzaCoordinate : CoordinateModel;
                                if( item.PdiPartenza.Coordinate )
                                {
                                    ItinerarioPdiPartenzaCoordinate = new CoordinateModel(
                                        item.PdiPartenza.Coordinate.Latitudine,
                                        item.PdiPartenza.Coordinate.Longitudine
                                    )
                                }
                                else
                                {
                                    ItinerarioPdiPartenzaCoordinate = null;
                                }
                                
                                let ItinerarioPdiPartenzaIdentifier = new PdiIdentifierModel(
                                    item.PdiPartenza.Identifier.Codice
                                )
 
                                ItinerarioPdiPartenza = new PdiBaseModel(
                                    ItinerarioPdiPartenzaIdentifier,
                                    item.PdiPartenza.Descrizione1,
                                    item.PdiPartenza.Descrizione2,
                                    item.PdiPartenza.Descrizione3,
                                    ItinerarioPdiPartenzaCoordinate
                                )
                                ItinerarioPdiArrivo = null;

                            }        
                            if( item.TipoOperazione === 2 )
                            {
                                ItinerarioOrarioArrivo = item.OrarioArrivo;

                                let ItinerarioPdiArrivoCoordinate : CoordinateModel;
                                if( item.PdiArrivo.Coordinate )
                                {
                                    ItinerarioPdiArrivoCoordinate = new CoordinateModel(
                                        item.PdiArrivo.Coordinate.Latitudine,
                                        item.PdiArrivo.Coordinate.Longitudine
                                    )
                                }

                                let ItinerarioPdiArrivoIdentifier = new PdiIdentifierModel(
                                    item.PdiArrivo.Identifier.Codice
                                )

                                ItinerarioPdiArrivo = new PdiBaseModel(
                                    ItinerarioPdiArrivoIdentifier,
                                    item.PdiArrivo.Descrizione1,
                                    item.PdiArrivo.Descrizione2,
                                    item.PdiArrivo.Descrizione3,
                                    ItinerarioPdiArrivoCoordinate
                                )
                                ItinerarioPdiPartenza = null;
                            }

                            item.RichiesteProdotti.forEach(rich => {
                                let RichiestaProdIdentifier = new RichiestaProdottiIdentifier(
                                    rich.Identifier.Esercizio,
                                    rich.Identifier.Numero,
                                    rich.Identifier.NumeroRigaItinerario,
                                    rich.Identifier.NumeroRigaMissione,
                                    rich.Identifier.Registro,
                                    rich.Identifier.NumeroRigaStato
                                )

                                let RichiestaProd = new RichiestaProdottiModel (
                                    RichiestaProdIdentifier,
                                    rich.StatoRichiesta,
                                    rich.DataComunicazione,
                                    rich.OraComunicazione,
                                    rich.Coordinate
                                )

                                RichiesteProdotti.push(RichiestaProd);    
                            })
                            
                            let Itinerario = new ItinerarioModel(
                                ItinerarioIdentifier,
                                item.Annotazioni,
                                ItinerarioOrarioPartenza,
                                ItinerarioOrarioArrivo,
                                item.Quantita,
                                ItinerarioCausale,
                                item.TipoOperazione,
                                ItinerarioArticolo,
                                ItinerarioPdiPartenza,
                                ItinerarioPdiArrivo,
                                RichiesteProdotti
                            )

                            ItinerariPrenotazione.push(Itinerario);
                        })

                        let color_background: string;
                        let color_siren: string;
                        let color_border: string;
                        let arrow_detail: string;

                        if( element.Prenotazione.StatoRichiesta === 6 || element.Prenotazione.StatoRichiesta === 8)
                        {
                            color_background = 'success';
                            color_siren = 'ordinario_bianco';
                        }
                        if( element.Prenotazione.StatoRichiesta === 7)
                        {
                            color_background = 'warning';
                            color_siren = 'ordinario';
                        }
                        if( element.Prenotazione.StatoRichiesta === 4 )
                        {
                            color_border = 'border-item-close';
                            arrow_detail = 'false';
                        }

                        let Prenotazione = new PrenotazioneModel(
                            PrenotazioneIdentifier,
                            element.Prenotazione.Assegnazione,
                            element.Prenotazione.DataViaggio,
                            element.Prenotazione.OraViaggio,
                            element.Prenotazione.StatoRichiesta,
                            color_background,
                            color_siren,
                            color_border,
                            arrow_detail,
                            PrenotazioneAutomezzo,
                            ItinerariPrenotazione
                        )
 
                        let RichiestaIdentifier = new RichiestaIdentifierModel(
                            element.Richiesta.Identifier.Esercizio,
                            element.Richiesta.Identifier.Registro,
                            element.Richiesta.Identifier.Numero
                        )

                        let RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                            element.Richiesta.Causale.Identifier.Codice
                        )

                        let RichiestaCausale = new CausaleTrasportiBaseModel(
                            RichiestaCausaleIdentifier,
                            element.Richiesta.Causale.Descrizione
                        )
  
                        let Richiesta = new RichiestaBaseModel(
                            RichiestaIdentifier,
                            element.Richiesta.TipoServizio,
                            RichiestaCausale,
                            element.Richiesta.Descrizione
                        )
  
                        let Missione = new MissioneModel(
                            Prenotazione,
                            Richiesta
                        )

                        missioni.push(Missione);
   
                    });
                    
                }   
                else
                {
                    //Errore
                } 
                return missioni;   
            }), 
           tap( missions => {
                this._missioni.next(missions);
            })    
        );        
    }

    sendStatoMissione(
        esercizio: string,
        registro: string,
        numero: number,
        numerorigamissione: number,
        numerorigaitinerario: number,
        nuovostato: number,
        ordine: number,
        lat: number,
        lng: number,
        assegnazione: string,
        temperatura: string,
        id_prodotto: string,
        id_contenitore: string,
        id_cella: string
    ) {
        let date = new Date();
        let giorno = +date.getDate();
        let gg: string;
        if(giorno < 10){
            gg = '0' + giorno.toString();
        }else{
            gg = giorno.toString();
        }
        let h: string;
        let ora = +date.getHours();
        if(ora < 10){
            h = '0' + ora.toString();
        }else{
            h = ora.toString();
        }
        let m: string;
        let minuti= +date.getMinutes();
        if(minuti < 10){
            m = '0' + minuti.toString();
        }else{
            m = minuti.toString();
        }
        let mese: string;
        if ((+date.getMonth() + 1) < 10){
            mese = '0' + (date.getMonth() + 1).toString();
        }else{
            mese = (date.getMonth() + 1).toString();
        }
        let data = date.getFullYear().toString() + mese + gg;
        let time = h + '.' + m;

        let utente: any;
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            utente = localStorage.getItem('utente');
        } else {
            this.authService.username.subscribe(username => {
                utente = username;
            });
        }

        let targa = localStorage.getItem('targa');

        if(ordine === 0){
            if (localStorage.getItem('gestionemissioni') !== null && localStorage.getItem('gestionemissioni') !== '1'){
                localStorage.setItem('gestionemissioni', '1');
            }
            localStorage.setItem(assegnazione, '');
        }

        if(nuovostato === 4){
            localStorage.removeItem(assegnazione);
        }

        console.log('Send stato');

        let Coordinate = new CoordinateModel(
            lat,
            lng
        );

        switch(this.azienda)
        {
            case 'svs':
                this.api_url = environment.api_url_svs;
                break;
            case 'cts':
                this.api_url = environment.api_url_cts;
                break;  
        }               

        return this.http.post<resultSetStatoMissione>(this.api_url+'Missione/SetStatoMissione',
                            { ItenirarioIdentifier: { NumeroRigaItinerario: numerorigaitinerario, NumeroRigaMissione: numerorigamissione, Esercizio: esercizio, Registro: registro,Numero: numero },
                            NuovoStato: nuovostato, Data: data, Ora: time, Coordinate: Coordinate }
        )
        .pipe(
            map(data => { console.log('data set stato', data);  return data; })
        );
    }

    getMissioneWeb(targa: string, barcode: string){

        switch(this.azienda)
        {
        case 'svs':
            this.api_url = environment.api_url_svs;
            break;
        case 'cts':
            this.api_url = environment.api_url_cts;
            break;  
        }

        return this.http.post<Missione>(
            this.api_url+'Missione/GetMissione',
            {MezzoIdentifier:{Targa:targa}, Barcode: barcode})
        .pipe( 
            map(resData => {
                let missione: MissioneModel;
                if(resData.Result){
                    let PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                        resData.Missione.Prenotazione.Identifier.Esercizio,
                        resData.Missione.Prenotazione.Identifier.Numero,
                        resData.Missione.Prenotazione.Identifier.NumeroRigaMissione,
                        resData.Missione.Prenotazione.Identifier.Registro
                    );

                    let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                        resData.Missione.Prenotazione.Automezzo.Identifier.Targa
                    );

                    let PrenotazioneAutomezzo = new AutomezzoBaseModel(
                        PrenotazioneAutomezzoIdentifier,
                        resData.Missione.Prenotazione.Automezzo.Sigla
                    );

                    let ItinerariPrenotazione: ItinerarioModel[] = [];

                    resData.Missione.Prenotazione.Itinerari.forEach(item => {
                        let RichiesteProdotti: RichiestaProdottiModel[] = [];

                        let ItinerarioIdentifier = new ItinerarioIdentifierModel(
                            item.Identifier.Esercizio,
                            item.Identifier.Registro,
                            item.Identifier.Numero,
                            item.Identifier.NumeroRigaItinerario
                        );

                        let ItinerarioCausale: CausaleTrasportiIdentifierModel;
                        if(item.CausaleIdentifier) {
                            ItinerarioCausale = new CausaleTrasportiIdentifierModel(
                                item.CausaleIdentifier.Codice
                            );
                        }else{
                            ItinerarioCausale = null;
                        }

                        let ItinerarioArticoloIdentifier = new ArticoloIdentifierModel(
                            item.Articolo.Identifier.Codice
                        );

                        let ItinerarioArticolo = new ArticoloBaseModel(
                            ItinerarioArticoloIdentifier,
                            item.Articolo.Flann,
                            item.Articolo.Attivo,
                            item.Articolo.Barcode,
                            item.Articolo.Descrizione,
                            item.Articolo.DescrizioneAggiuntiva
                        );

                        let ItinerarioPdiArrivo: PdiBaseModel;     
                        let ItinerarioPdiPartenza: PdiBaseModel;
                        let ItinerarioOrarioPartenza: string = '';
                        let ItinerarioOrarioArrivo: string = '';

                        if(item.TipoOperazione === 1){
                            ItinerarioOrarioPartenza = item.OrarioPartenza;

                            let ItinerarioPdiPartenzaCoordinate: CoordinateModel;
                            if(item.PdiPartenza.Coordinate){
                                ItinerarioPdiPartenzaCoordinate = new CoordinateModel(
                                    item.PdiPartenza.Coordinate.Latitudine,
                                    item.PdiPartenza.Coordinate.Longitudine
                                );
                            }

                            let ItinerarioPdiPartenzaIdentifier = new PdiIdentifierModel(
                                item.PdiPartenza.Identifier.Codice
                            );

                            ItinerarioPdiPartenza = new PdiBaseModel(
                                ItinerarioPdiPartenzaIdentifier,
                                item.PdiPartenza.Descrizione1,
                                item.PdiPartenza.Descrizione2,
                                item.PdiPartenza.Descrizione3,
                                ItinerarioPdiPartenzaCoordinate
                            )
                            ItinerarioPdiArrivo = null;
                        }

                        if(item.TipoOperazione === 2){
                            ItinerarioOrarioArrivo = item.OrarioArrivo;

                            let ItinerarioPdiArrivoCoordinate: CoordinateModel;
                            if(item.PdiArrivo.Coordinate){
                                ItinerarioPdiArrivoCoordinate = new CoordinateModel(
                                    item.PdiArrivo.Coordinate.Latitudine,
                                    item.PdiArrivo.Coordinate.Longitudine
                                );
                            }else{
                                ItinerarioPdiArrivoCoordinate = null;
                            }
                            
                            let ItinerarioPdiArrivoIdentifier = new PdiIdentifierModel(
                                item.PdiArrivo.Identifier.Codice
                            );

                            ItinerarioPdiArrivo = new PdiBaseModel(
                                ItinerarioPdiArrivoIdentifier,
                                item.PdiArrivo.Descrizione1,
                                item.PdiArrivo.Descrizione2,
                                item.PdiArrivo.Descrizione3,
                                ItinerarioPdiArrivoCoordinate
                            )
                            ItinerarioPdiPartenza = null;
                        }

                        let RichiestaProdIdentifier: RichiestaProdottiIdentifier;
                        if(item.RichiesteProdotti && item.RichiesteProdotti.length > 0){
                            item.RichiesteProdotti.forEach(rich => {
                                RichiestaProdIdentifier = new RichiestaProdottiIdentifier(
                                    rich.Identifier.Esercizio,
                                    rich.Identifier.Numero,
                                    rich.Identifier.NumeroRigaItinerario,
                                    rich.Identifier.NumeroRigaMissione,
                                    rich.Identifier.Registro,
                                    rich.Identifier.NumeroRigaStato
                                )

                                let RichiestaProd = new RichiestaProdottiModel(
                                    RichiestaProdIdentifier,
                                    rich.StatoRichiesta,
                                    rich.DataComunicazione,
                                    rich.OraComunicazione,
                                    rich.Coordinate
                                );

                                RichiesteProdotti.push(RichiestaProd);    
                            });
                        }
                        else{
                            RichiesteProdotti = null;
                        }

                        let Itinerario = new ItinerarioModel(
                            ItinerarioIdentifier,
                            item.Annotazioni,
                            ItinerarioOrarioPartenza,
                            ItinerarioOrarioArrivo,
                            item.Quantita,
                            ItinerarioCausale,
                            item.TipoOperazione,
                            ItinerarioArticolo,
                            ItinerarioPdiPartenza,
                            ItinerarioPdiArrivo,
                            RichiesteProdotti
                        );

                        ItinerariPrenotazione.push(Itinerario);
                    });

                    let color_background: string;
                    let color_siren: string;
                    let color_border: string;
                    let arrow_detail: string = 'true';

                    if( resData.Missione.Prenotazione.StatoRichiesta === 6 || resData.Missione.Prenotazione.StatoRichiesta === 8)
                    {
                        color_background = 'success';
                        color_siren = 'ordinario_bianco';
                    }
                    if( resData.Missione.Prenotazione.StatoRichiesta === 7)
                    {
                        color_background = 'warning';
                        color_siren = 'ordinario';
                    }
                    if( resData.Missione.Prenotazione.StatoRichiesta === 4 )
                    {
                        color_border = 'border-item-close';
                        arrow_detail = 'false';
                    }
                    let Prenotazione = new PrenotazioneModel(
                        PrenotazioneIdentifier,
                        resData.Missione.Prenotazione.Assegnazione,
                        resData.Missione.Prenotazione.DataViaggio,
                        resData.Missione.Prenotazione.OraViaggio,
                        resData.Missione.Prenotazione.StatoRichiesta,
                        color_background,
                        color_siren,
                        color_border,
                        arrow_detail,
                        PrenotazioneAutomezzo,
                        ItinerariPrenotazione
                    );

                    let RichiestaIdentifier = new RichiestaIdentifierModel(
                        resData.Missione.Richiesta.Identifier.Esercizio,
                        resData.Missione.Richiesta.Identifier.Registro,
                        resData.Missione.Richiesta.Identifier.Numero
                    );

                    let RichiestaCausaleIdentifier: CausaleTrasportiIdentifierModel;
                    let RichiestaCausale: CausaleTrasportiBaseModel;

                    if(resData.Missione.Richiesta.Causale){
                        RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                            resData.Missione.Richiesta.Causale.Identifier.Codice
                        );
                        
                        RichiestaCausale = new CausaleTrasportiBaseModel(
                            RichiestaCausaleIdentifier,
                            resData.Missione.Richiesta.Causale.Descrizione
                        );
                    }else{
                        RichiestaCausale = null;
                    }
                    
                    let Richiesta = new RichiestaBaseModel(
                        RichiestaIdentifier,
                        resData.Missione.Richiesta.TipoServizio,
                        RichiestaCausale,
                        resData.Missione.Richiesta.Descrizione
                    );

                    missione = new MissioneModel(
                        Prenotazione,
                        Richiesta
                    );
                }else{
                    //Errore
                } 

                return missione;   
            }), 
            tap( missions => {
                this._missione.next(missions);
            })   
        );              
    }

    /* async getMissione(targa: string, barcode: string):Promise<MissioneModel> {
  
        if( !this.platform.is('desktop') )
        {
            //Lettura dei dati dal database locale
            let query : string = '';
            let res: any;
            console.log(`going to create a connection`)
            let Missione : MissioneModel;
            let db: SQLiteDBConnection;

            try 
            {
                db = await this.SetConnection();
           
                query = `SELECT DISTINCT T.*, P.numero_riga, p.data_viaggio, p.ora_viaggio, p.stato_richiesta, i.numero_riga_itinerario, i.tipo_operazione, i.orario_arrivo, i.orario_partenza, i.pdi_lat, i.pdi_lng, i.pdi_descr1, i.pdi_descr2, i.pdi_descr3, i.pdi_codice, i.quantita, i.articolo_barcode, i.articolo_codice, i.ordine, rp.numero_riga_stato, rp.numero_riga_missione,rp.stato, rp.lat, rp.lng  FROM testata AS T INNER JOIN PRENOTAZIONI AS P on P.esercizio=T.esercizio and P.REGISTRO=T.REGISTRO and P.NUMERO=T.NUMERO INNER JOIN itinerari AS I ON I.esercizio=P.esercizio AND i.numero=T.numero AND i.registro=t.registro LEFT JOIN richiesta_prodotti AS rp ON rp.esercizio=P.esercizio AND rp.registro=P.registro AND rp.numero=P.numero AND rp.numero_riga_itinerario=i.numero_riga_itinerario AND rp.tipo_operazione=i.tipo_operazione WHERE T.id_automezzo='${targa}' AND t.id_assegnazione='${barcode}' ORDER BY i.ordine,i.numero_riga_itinerario`;
                
                console.log('query select testata + prenotazioni + itinerari + prodotti', query);
                res = await db.query(query);
                console.log(`res della select testata + prenotazioni + itinerari: ${JSON.stringify(res)}`)
                
                console.log('risultato?',JSON.stringify(res.values));

                let pdi_partenza = 0;
                let pdi_destinazione = 0;
                let esercizio = '';
                let registro = '';
                let numero = 0;
                let id_assegnazione = '';
                let data_viaggio = '';
                let ora_viaggio = '';
                let stato_richiesta = 0;
                let descrizione = '';
                let tipo_servizio = 901;
                let causale ='';
                let causale_descr = '';
                let PrenotazioneAutomezzo: AutomezzoBaseModel;
                let PrenotazioneIdentifier: PrenotazioneIdentifierModel;
                let ItinerariPrenotazione: ItinerarioModel[];
                let Itinerario : ItinerarioModel;
                let prev_numero: number =  0;
                let prev_numero_riga_itinerario: number = 0;

                res.values.forEach( item => {
                    
                    if( prev_numero === 0 )
                    {
                        console.log('1');
                        console.log('item',JSON.stringify(item));
                        pdi_partenza = item.pdi_partenza;
                        console.log('pdi_partenza', pdi_partenza);
                        pdi_destinazione = item.pdi_destinazione;
                        esercizio = item.esercizio;
                        registro = item.registro;
                        numero = item.numero;
                        id_assegnazione =item.id_assegnazione;
                        data_viaggio = item.data_viaggio;
                        ora_viaggio = item.ora_viaggio;
                        stato_richiesta = item.stato_richiesta;
                        descrizione = item.descrizione;
                        tipo_servizio = item.id_tipomezzo;
                        causale = item.causale;    
                        causale_descr = item.causale_descr;
                       
                        PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                            esercizio,
                            numero,
                            parseInt(item.numero_riga),
                            registro
                        ) 
                        
                        let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                            item.id_automezzo
                        )

                        PrenotazioneAutomezzo = new AutomezzoBaseModel(
                            PrenotazioneAutomezzoIdentifier,
                            ''
                        )

                        ItinerariPrenotazione = [];

                        prev_numero = numero;
                    }        

                    let ItinerarioIdentifier: ItinerarioIdentifierModel;
                    let ItinerarioCausaleIdentifier = new  CausaleTrasportiIdentifierModel(
                        causale
                    )

                    let RichiesteProdotti: RichiestaProdottiModel[] = [];

                    if( prev_numero_riga_itinerario!==item.numero_riga_itinerario || prev_numero_riga_itinerario === 0 )
                    {
                        //Nuova riga di stato per l'itinerario
                        if( item.numero_riga_missione !== '' && item.numero_riga_missione && item.numero_riga_missione !== '' ){
                            let richiestaProdotti: RichiestaProdottiModel;
                            let richiestaProdottiIdentifier = new RichiestaProdottiIdentifier(
                                esercizio,
                                numero,
                                item.numero_riga_itinerario,
                                item.numero_riga_missione,
                                registro,
                                item.numero_riga_stato
                            )

                            let coordinate = new CoordinateModel(
                                item.lat,
                                item.lng
                            )

                            richiestaProdotti = new RichiestaProdottiModel(
                                richiestaProdottiIdentifier,
                                item.stato,
                                item.data_comunicazione,
                                item.ora_comunicazione,
                                coordinate
                            )

                            RichiesteProdotti.push(richiestaProdotti);
                            }
                        }

                    console.log('2');
                    
                    ItinerarioIdentifier = new ItinerarioIdentifierModel(
                        esercizio,
                        registro,
                        numero,
                        item.numero_riga_itinerario
                    )

                    let ItinerarioPdiArrivo: PdiBaseModel = null;     
                    let ItinerarioPdiPartenza: PdiBaseModel = null;

                    if( item.tipo_operazione === 1 )
                    {
                        let ItinerarioPdiPartenzaCoordinate : CoordinateModel;
                        if( item.pdi_lat && item.pdi_lng )
                        {
                            ItinerarioPdiPartenzaCoordinate = new CoordinateModel(
                                parseInt(item.pdi_lat),
                                parseInt(item.pdi_lng)
                            )
                        }
                        
                        let ItinerarioPdiPartenzaIdentifier = new PdiIdentifierModel(
                            pdi_partenza
                        )

                        ItinerarioPdiPartenza = new PdiBaseModel(
                            ItinerarioPdiPartenzaIdentifier,
                            item.pdi_descr1,
                            item.pdi_descr2,
                            item.pdi_descr3,
                            ItinerarioPdiPartenzaCoordinate
                        )
                        ItinerarioPdiArrivo = null;

                    }        
                    if( item.tipo_operazione === 2 )
                    {
                        let ItinerarioPdiArrivoCoordinate : CoordinateModel;
                        if( item.pdi_lat && item.pdi_lng )
                        {
                            ItinerarioPdiArrivoCoordinate = new CoordinateModel(
                                parseInt(item.pdi_lat),
                                parseInt(item.pdi_lng)
                            )
                        }
                        
                        let ItinerarioPdiArrivoIdentifier = new PdiIdentifierModel(
                            pdi_destinazione
                        )

                        ItinerarioPdiArrivo = new PdiBaseModel(
                            ItinerarioPdiArrivoIdentifier,
                            item.pdi_descr1,
                            item.pdi_descr2,
                            item.pdi_descr3,
                            ItinerarioPdiArrivoCoordinate
                        )
                        ItinerarioPdiPartenza = null;
                    }

                    let ArticoloIdentifier = new ArticoloIdentifierModel(
                        item.articolo_codice
                    )
                    let Articolo = new ArticoloBaseModel(
                        ArticoloIdentifier,
                        '',
                        true,
                        item.articolo_barcode,
                        item.articolo_descrizione,
                        ''
                    )

                    Itinerario = new ItinerarioModel(
                        ItinerarioIdentifier,
                        '',
                        item.orario_partenza,
                        item.orario_arrivo,
                        item.quantita,
                        ItinerarioCausaleIdentifier,
                        item.tipo_operazione,
                        Articolo,
                        ItinerarioPdiPartenza,
                        ItinerarioPdiArrivo,
                        RichiesteProdotti
                    )

                    ItinerariPrenotazione.push(Itinerario);

                });  

                let color_background: string;
                let color_siren: string;
                let color_border: string;
                let arrow_detail: string = 'true';

                if( stato_richiesta === 6 || stato_richiesta === 8)
                {
                    color_background = 'success';
                    color_siren = 'ordinario_bianco';
                }
                if( stato_richiesta === 7)
                {
                    color_background = 'warning';
                    color_siren = 'ordinario';
                }
                if( stato_richiesta === 4)
                {
                    color_border = 'border-item-close';
                    arrow_detail = 'false';
                }
                //E' necessario ordinare gli itinerari per ordine in modo da vere una corretta visualizzazione
                console.log( 'itinerari', JSON.stringify(ItinerariPrenotazione));

                let Prenotazione = new PrenotazioneModel(
                    PrenotazioneIdentifier,
                    id_assegnazione,
                    data_viaggio,
                    ora_viaggio,
                    stato_richiesta,
                    color_background,
                    color_siren,
                    color_border,
                    arrow_detail,
                    PrenotazioneAutomezzo,
                    ItinerariPrenotazione
                )                

                let RichiestaIdentifier = new RichiestaIdentifierModel(
                    esercizio,
                    registro,
                    numero
                )

                let RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                    causale
                )

                let RichiestaCausale = new CausaleTrasportiBaseModel(
                    RichiestaCausaleIdentifier,
                    causale_descr
                )

                let Richiesta = new RichiestaBaseModel(
                    RichiestaIdentifier,
                    tipo_servizio,
                    RichiestaCausale,
                    descrizione
                )

                Missione = new MissioneModel(
                    Prenotazione,
                    Richiesta
                )
                console.log('missione nuova', JSON.stringify(Missione));  
                this._missione.next(Missione);
     
                // await db.close();
                /* await this.sqliteService.closeConnection('missioni');
                console.log(`after db.close`); */

              /*   return Missione;  
            }
            catch{
                //errore
            }
        }
  
    } */ 

    refreshMissioneWeb(targa: string, barcode: string){

        switch(this.azienda)
        {
            case 'svs':
                this.api_url = environment.api_url_svs;
                break;
            case 'cts':
                this.api_url = environment.api_url_cts;
                break;  
        }
        return this.http.post<Missione>(
            this.api_url+'Missione/GetMissione',
            { MezzoIdentifier: { Targa: targa }, Barcode: barcode })
        .pipe( 
            map(resData => {
                console.log('resData api', resData);
                let missione: MissioneModel;
                if(resData.Result){
                    let PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                        resData.Missione.Prenotazione.Identifier.Esercizio,
                        resData.Missione.Prenotazione.Identifier.Numero,
                        resData.Missione.Prenotazione.Identifier.NumeroRigaMissione,
                        resData.Missione.Prenotazione.Identifier.Registro
                    );
  
                    let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                        resData.Missione.Prenotazione.Automezzo.Identifier.Targa
                    );

                    let PrenotazioneAutomezzo = new AutomezzoBaseModel(
                        PrenotazioneAutomezzoIdentifier,
                        resData.Missione.Prenotazione.Automezzo.Sigla
                    );
  
                    let ItinerariPrenotazione: ItinerarioModel[] = [];
                    resData.Missione.Prenotazione.Itinerari.forEach(item => {
                        let RichiesteProdotti: RichiestaProdottiModel[] = [];

                        let ItinerarioIdentifier = new ItinerarioIdentifierModel(
                            item.Identifier.Esercizio,
                            item.Identifier.Registro,
                            item.Identifier.Numero,
                            item.Identifier.NumeroRigaItinerario
                        );

                        let ItinerarioCausale: CausaleTrasportiIdentifierModel;
                        if(item.CausaleIdentifier){
                            ItinerarioCausale = new CausaleTrasportiIdentifierModel(
                                item.CausaleIdentifier.Codice
                            );
                        }else{
                            ItinerarioCausale = null;
                        }

                        let ItinerarioArticoloIdentifier = new ArticoloIdentifierModel(
                            item.Articolo.Identifier.Codice
                        );

                        let ItinerarioArticolo = new ArticoloBaseModel(
                            ItinerarioArticoloIdentifier,
                            item.Articolo.Flann,
                            item.Articolo.Attivo,
                            item.Articolo.Barcode,
                            item.Articolo.Descrizione,
                            item.Articolo.DescrizioneAggiuntiva
                        );

                        let ItinerarioPdiArrivo: PdiBaseModel;     
                        let ItinerarioPdiPartenza: PdiBaseModel;
                        let ItinerarioOrarioPartenza: string = '';
                        let ItinerarioOrarioArrivo: string = '';

                        if(item.TipoOperazione === 1){
                            ItinerarioOrarioPartenza = item.OrarioPartenza;

                            let ItinerarioPdiPartenzaCoordinate: CoordinateModel;
                            if(item.PdiPartenza.Coordinate){
                                ItinerarioPdiPartenzaCoordinate = new CoordinateModel(
                                    item.PdiPartenza.Coordinate.Latitudine,
                                    item.PdiPartenza.Coordinate.Longitudine
                                );
                            }

                            let ItinerarioPdiPartenzaIdentifier = new PdiIdentifierModel(
                                item.PdiPartenza.Identifier.Codice
                            );

                            ItinerarioPdiPartenza = new PdiBaseModel(
                                ItinerarioPdiPartenzaIdentifier,
                                item.PdiPartenza.Descrizione1,
                                item.PdiPartenza.Descrizione2,
                                item.PdiPartenza.Descrizione3,
                                ItinerarioPdiPartenzaCoordinate
                            );

                            ItinerarioPdiArrivo = null;
                        } 

                        if(item.TipoOperazione === 2){
                            ItinerarioOrarioArrivo = item.OrarioArrivo;

                            let ItinerarioPdiArrivoCoordinate: CoordinateModel;
                            if(item.PdiArrivo.Coordinate){
                                ItinerarioPdiArrivoCoordinate = new CoordinateModel(
                                    item.PdiArrivo.Coordinate.Latitudine,
                                    item.PdiArrivo.Coordinate.Longitudine
                                );
                            }else{
                                ItinerarioPdiArrivoCoordinate = null;
                            }
                            
                            let ItinerarioPdiArrivoIdentifier = new PdiIdentifierModel(
                                item.PdiArrivo.Identifier.Codice
                            );

                            ItinerarioPdiArrivo = new PdiBaseModel(
                                ItinerarioPdiArrivoIdentifier,
                                item.PdiArrivo.Descrizione1,
                                item.PdiArrivo.Descrizione2,
                                item.PdiArrivo.Descrizione3,
                                ItinerarioPdiArrivoCoordinate
                            )
                            ItinerarioPdiPartenza = null;
                        }

                        let RichiestaProdIdentifier : RichiestaProdottiIdentifier;
                        if(item.RichiesteProdotti && item.RichiesteProdotti.length > 0){
                            item.RichiesteProdotti.forEach(rich => {
                                RichiestaProdIdentifier = new RichiestaProdottiIdentifier(
                                    rich.Identifier.Esercizio,
                                    rich.Identifier.Numero,
                                    rich.Identifier.NumeroRigaItinerario,
                                    rich.Identifier.NumeroRigaMissione,
                                    rich.Identifier.Registro,
                                    rich.Identifier.NumeroRigaStato
                                );

                                let RichiestaProd = new RichiestaProdottiModel (
                                    RichiestaProdIdentifier,
                                    rich.StatoRichiesta,
                                    rich.DataComunicazione,
                                    rich.OraComunicazione,
                                    rich.Coordinate
                                );

                                RichiesteProdotti.push(RichiestaProd);    
                            });
                        }else{
                            RichiesteProdotti = null;
                        }

                        let Itinerario = new ItinerarioModel(
                            ItinerarioIdentifier,
                            item.Annotazioni,
                            ItinerarioOrarioPartenza,
                            ItinerarioOrarioArrivo,
                            item.Quantita,
                            ItinerarioCausale,
                            item.TipoOperazione,
                            ItinerarioArticolo,
                            ItinerarioPdiPartenza,
                            ItinerarioPdiArrivo,
                            RichiesteProdotti
                        );

                        ItinerariPrenotazione.push(Itinerario);
                    });

                    let color_background: string;
                    let color_siren: string;
                    let color_border: string;
                    let arrow_detail: string = 'true';

                    if( resData.Missione.Prenotazione.StatoRichiesta === 6 || resData.Missione.Prenotazione.StatoRichiesta === 8)
                    {
                        color_background = 'success';
                        color_siren = 'ordinario_bianco';
                    }
                    if( resData.Missione.Prenotazione.StatoRichiesta === 7)
                    {
                        color_background = 'warning';
                        color_siren = 'ordinario';
                    }
                    if( resData.Missione.Prenotazione.StatoRichiesta === 4)
                    {
                        color_border = 'border-item-close';
                        arrow_detail = 'false';
                    }
                        
                    let Prenotazione = new PrenotazioneModel(
                        PrenotazioneIdentifier,
                        resData.Missione.Prenotazione.Assegnazione,
                        resData.Missione.Prenotazione.DataViaggio,
                        resData.Missione.Prenotazione.OraViaggio,
                        resData.Missione.Prenotazione.StatoRichiesta,
                        color_background,
                        color_siren,
                        color_border,
                        arrow_detail,
                        PrenotazioneAutomezzo,
                        ItinerariPrenotazione
                    );

                    let RichiestaIdentifier = new RichiestaIdentifierModel(
                        resData.Missione.Richiesta.Identifier.Esercizio,
                        resData.Missione.Richiesta.Identifier.Registro,
                        resData.Missione.Richiesta.Identifier.Numero
                    );
                    
                    let RichiestaCausaleIdentifier: CausaleTrasportiIdentifierModel;
                    let RichiestaCausale: CausaleTrasportiBaseModel;

                    if(resData.Missione.Richiesta.Causale){
                        RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                            resData.Missione.Richiesta.Causale.Identifier.Codice
                        );
                        
                        RichiestaCausale = new CausaleTrasportiBaseModel(
                            RichiestaCausaleIdentifier,
                            resData.Missione.Richiesta.Causale.Descrizione
                        );
                    }else{
                        RichiestaCausale = null;
                    }
                    
                    let Richiesta = new RichiestaBaseModel(
                        RichiestaIdentifier,
                        resData.Missione.Richiesta.TipoServizio,
                        RichiestaCausale,
                        resData.Missione.Richiesta.Descrizione
                    );

                    missione = new MissioneModel(
                        Prenotazione,
                        Richiesta
                    );
                }else{
                    //Errore
                } 
                return missione;   
            }), 
            tap(missions => {
                this._missione.next(missions);
            })       
        );                
    }

    /* async refreshMissione(targa: string, barcode: string):Promise<MissioneModel>{

        switch(this.azienda)
        {
            case 'svs':
                this.api_url = environment.api_url_svs;
                break;
            case 'cts':
                this.api_url = environment.api_url_cts;
                break;  
        }

        return this.getMissione(targa,barcode);
   
    } */

    contaMissioni(){
        let utente: any;
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            utente = localStorage.getItem('utente');
        } else {
            this.authService.username.subscribe(username => {
                utente = username;
            });
        }

        let tg = localStorage.getItem('targa');
        let date = new Date();
        let anno = date.getFullYear();
        let mese;
        let giorno;
        if ((+date.getMonth() + 1) < 10) {
            mese = '0' + (date.getMonth() + 1).toString();
        } else {
            mese = (date.getMonth() + 1).toString();
        }
        if (+date.getDate() < 10) {
            giorno = '0' + +date.getDate();
        } else {
            giorno = +date.getDate();
        }
        let data = anno.toString() + mese + giorno.toString();
        let stringa = '{"targa":"' + tg + '","username":"' + utente + '","data":"' + data + '","viaggio":""}';
        console.log('stringa', stringa);
        return this.http
        .get<any>(
            `https://demo.trasportosangueorgani.it/ws/conta-missioni.php?string=${stringa}`,
            { headers: { skip: 'true' } }
        ).pipe(
            map(resData => {
                console.log('resdata contamissioni',resData);
                return resData;
            })
        );

    }
   
    async showAlert(title, message) {
        let alert = await this.alertCtrl.create({
            header: title,
            message: message,
            buttons: ["OK"]
        });
        alert.present();
    }

    /* async scan( id_device ): Promise<void>{

        
        let permission_ble = false;

        let db: SQLiteDBConnection;
        let query: string;
        let res: any;

        //Check permission
        this.permissions.checkPermission(this.permissions.PERMISSION.BLUETOOTH).then(
          result => { console.log('Has permission?',result.hasPermission); permission_ble = result.hasPermission;}, 
          err => this.permissions.requestPermission(this.permissions.PERMISSION.BLUETOOTH)
        );
        console.log('permission_ble', permission_ble);
        if( !permission_ble )
        {
          permission_ble = (await this.permissions.requestPermission(this.permissions.PERMISSION.BLUETOOTH)).hasPermission;
        }
        
        console.log('permission_ble 2', permission_ble); 
    
        if(permission_ble){
          try {
            BleClient.initialize();
            
            console.log('uid convertito', this.bluSensorService);
            await BleClient.disconnect(id_device);   

            console.log('read bleclient');
            BleClient.connect(id_device);   
            
            (await BleClient.read(id_device , this.bluSensorService, this.bluSensorCharacteristic)
                .then( async data => { 
                    console.log('dataview to numbers', data);

                    if( localStorage.getItem('networkok') && localStorage.getItem('networkok') === '1' ){
                        this.saveDataloggerTemperature( id_device, data ).subscribe( data => console.log('success', data.success)) ;
                    }
                    else{
                        //Salvataggio nella fifo
                        let rawAdvertisement_Buffer: Number[];
                        let temp: string='';
                        let id_assegnazione: string = '';
                        if (localStorage.getItem(id_device) !== '' && localStorage.getItem(id_device) !== null) {
                            id_assegnazione = localStorage.getItem(id_device);
                        }    
                        id_assegnazione = '1234';
                        if( id_assegnazione !== '' )
                        {
                            rawAdvertisement_Buffer = dataViewToNumbers(data);
                            if( rawAdvertisement_Buffer[0] === 1 )
                            {
                                temp = '-';
                            }
                            temp = temp + rawAdvertisement_Buffer[1] + ',' + rawAdvertisement_Buffer[2];
                    
                            console.log('temperatura', temp);
                   
                            let today = new Date();
                            let anno = today.getFullYear();
                            let mese;
                            let giorno;
                            let minuti;
                            let ora;
                
                            if ((+today.getMonth() + 1) < 10) {
                                mese = '0' + +(today.getMonth() + 1);
                            } else {
                                mese = +(today.getMonth() + 1);
                            }
                            if (+today.getDate() < 10) {
                                giorno = '0' + +today.getDate();
                            } else {
                                giorno = +today.getDate();
                            }
                            let date = anno.toString() + mese.toString() + giorno.toString();
                
                            if ((+today.getHours() + 1) < 10) {
                                ora = '0' + +(today.getHours());
                            } else {
                                ora = +(today.getMonth());
                            }
                            if (+today.getMinutes() < 10) {
                                minuti = '0' + +today.getMinutes();
                            } else {
                                minuti = +today.getMinutes();
                            }
                            let time = ora.toString() + '.' + minuti.toString();
                
                            this.device_temp = { id_assegnazione, id_device, temp, date, time };
                
                            try 
                            {
                                
                                let url = 'https://demo.trasportosangueorgani.it/ws/temperature.php?';
                                let type_call = 'get';
                                let params = `idcontenitore=${this.device_temp.id_device}&temperatura=${this.device_temp.temp}&barcode=${this.device_temp.id_assegnazione}&data=${this.device_temp.date}&ora=${this.device_temp.time}`;
            
                                db = await this.SetConnection();
            
                                query = `INSERT INTO rest_service_fifo ( url_method, type, params, headers, data, ora ) VALUES ( '${url}', '${type_call}', '${params}', '{ skip: 'true' }', '${this.device_temp.date}', '${this.device_temp.time}')`;
                                console.log('query insert rest_service_fifo', query);
                                res = await db.execute(query);
                                console.log(`res della insert rest_service_fifo: ${JSON.stringify(res)}`)

                                // await db.close();
                                await this.sqliteService.closeConnection('missioni');
                                console.log(`after db.close`);
                            
                            }
                            catch{
                                //Errore
            
            
            
                            }  
                        }    
                    }

                } ));

            await BleClient.disconnect(id_device).then(  () => { 
                console.log('read bleclient');
                BleClient.connect(id_device);   
                
                BleClient.read(id_device , this.bluSensorService, this.bluSensorCharacteristic)
                .then( data => { 
                    console.log('dataview to numbers', data);
                    this.saveDataloggerTemperature( id_device, data ).subscribe( data => console.log('success', data.success));
                });

                      
                setTimeout(async () => {
                    await BleClient.disconnect(id_device);
                    console.log('disconnected from device');
                }, 60000);
            });
          }  
          catch (error) {
            console.error(error);
            let toast = await this.toastCtrl.create({ message: error + id_device , duration: 10000, position: 'bottom' });
            //toast.present();

          }

        } 
    } */

    replaceAll(str, find, replace) {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
    }

    saveDataloggerTemperature( id_device: string, rawAdvertisement: DataView)
      {
        
        let rawAdvertisement_Buffer: Number[];
        let temp: string='';
        let id_assegnazione: string = '';


        //Ricerco nel localstorage se il dispositivo rilevato è assegnato ad un contenitore "attivato"
        if (localStorage.getItem(id_device) !== '' && localStorage.getItem(id_device) !== null) {
            id_assegnazione = localStorage.getItem(id_device);
        }    
        id_assegnazione = '1234';
        if( id_assegnazione !== '' )
        {
            rawAdvertisement_Buffer = dataViewToNumbers(rawAdvertisement);
            if( rawAdvertisement_Buffer[0] === 1 )
            /* {
                temp = '+';
            } 
            else*/
            {
                temp = '-';
            }
            temp = temp + rawAdvertisement_Buffer[1] + ',' + rawAdvertisement_Buffer[2];
    
            console.log('temperatura', temp);
   
            let today = new Date();
            let anno = today.getFullYear();
            let mese;
            let giorno;
            let minuti;
            let ora;

            if ((+today.getMonth() + 1) < 10) {
                mese = '0' + +(today.getMonth() + 1);
            } else {
                mese = +(today.getMonth() + 1);
            }
            if (+today.getDate() < 10) {
                giorno = '0' + +today.getDate();
            } else {
                giorno = +today.getDate();
            }
            let date = anno.toString() + mese.toString() + giorno.toString();

            if ((+today.getHours() + 1) < 10) {
                ora = '0' + +(today.getHours());
            } else {
                ora = +(today.getMonth());
            }
            if (+today.getMinutes() < 10) {
                minuti = '0' + +today.getMinutes();
            } else {
                minuti = +today.getMinutes();
            }
            let time = ora.toString() + '.' + minuti.toString();

            this.device_temp = { id_assegnazione, id_device, temp, date, time };

            console.log('device_temp',this.device_temp);
            console.log('id_assegnazione', this.device_temp.id_assegnazione);
            console.log('id_device', this.device_temp.id_device);
            console.log('date', this.device_temp.date);
            console.log('time', this.device_temp.time);
            console.log('temp', this.device_temp.temp);
            console.log('chiamata a temperature.php', `https://demo.trasportosangueorgani.it/ws/temperature.php?idcontenitore=${this.device_temp.id_device}&temperatura=${this.device_temp.temp}&barcode=${this.device_temp.id_assegnazione}&data=${this.device_temp.date}&ora=${this.device_temp.time}`);
         
            try {
                return this.http
            .get<any>(
                    `https://demo.trasportosangueorgani.it/ws/temperature.php?idcontenitore=${this.device_temp.id_device}&temperatura=${this.device_temp.temp}&barcode=${this.device_temp.id_assegnazione}&data=${this.device_temp.date}&ora=${this.device_temp.time}`,
                    { headers: { skip: 'true' } }
                )
                .pipe(
                    map( resData => {
                        console.log('resData success', resData.success);
                        console.log('resData message', resData.message);
                        return resData;
                    })
                );
            }
            catch (error) {
                console.error(error);
            /* let toast = await this.toastCtrl.create({ message: error , duration: 10000, position: 'bottom' });
            toast.present(); */
            }    
        }
    }

    getTargheAutomezzi(){
    /* if( this.userIsAuthenticated )
    {  */
        console.log('sono qui getTargheAutomezzi');

        let date = new Date();
        let anno = date.getFullYear();
        let mese: string;
        let giorno;
        if ((+date.getMonth() + 1) < 10) {
            mese = '0' + (date.getMonth() + 1).toString();
        } else {
            mese = (date.getMonth() + 1).toString();
        }
        if (+date.getDate() < 10) {
            giorno = '0' + +date.getDate();
        } else {
            giorno = +date.getDate();
        }
        let data = anno.toString() + mese + giorno.toString();

        console.log('azienda getautomezzi', this.azienda);
        console.log('azienda getautomezzi da localstorage', localStorage.getItem('azienda'));
        switch(localStorage.getItem('azienda'))
        {
        case 'svs':
            this.api_url = environment.api_url_svs;
            break;
        case 'cts':
            this.api_url = environment.api_url_cts;
            break;
        }

        console.log('getaumezziassegnati', this.api_url + 'Missione/GetAutomezziAssegnati' + data );
        return this.http
        .post<Automezzo>(
            this.api_url + 'Missione/GetAutomezziAssegnati',
            { Data: data }
        )
        .pipe(
            take(1),
            map(resData => {
                console.log('resData', JSON.stringify(resData));                    
                let automezzi : string = '';
                
                if( resData.Result )
                {
                    console.log('sono dentro il true');
                    resData.Automezzi.forEach( item => {

                        console.log('targa', item.Identifier.Targa);
                        let AutomezzoIdentifier = new AutomezzoIdentifierModel(
                            item.Identifier.Targa
                        )

                        let Automezzo = new AutomezzoBaseModel(
                            AutomezzoIdentifier,
                            item.Sigla
                        )
                        automezzi = automezzi + item.Identifier.Targa + '|';
                        // Automezzi.push(Automezzo);
                    })
                    
                    automezzi = automezzi.substring(0,automezzi.length-1);
                    console.log('automezzi nel service', automezzi );
                    return automezzi;
                }
            }),
            /* tap( mezzi => {
                this._automezzi.next(mezzi);
            })  */ 
        )    
        // }
    }

    async getMissionsToCache()
    {
        console.log('prima di inserimento in db');

        let res: any;
        let res2: any;
        let res3: any;
        let res4: any;
        let res5: any;
        let res6: any;
        let resp: any;
        let query: string = '';
        let count_itinerari: number;
        let errore:boolean = false;
        let db : SQLiteDBConnection;

        this.getTargheAutomezzi()
        .subscribe( async tgs => {
            localStorage.setItem('targhe', tgs);
            let targhe = tgs.split('|');

            targhe.forEach( tg => 
            {
                console.log('targa', tg);
           
                let date = new Date();
                let anno = date.getFullYear();
                let mese: string;
                let giorno;
                if ((+date.getMonth() + 1) < 10) {
                    mese = '0' + (date.getMonth() + 1).toString();
                } else {
                    mese = (date.getMonth() + 1).toString();
                }
                if (+date.getDate() < 10) {
                    giorno = '0' + +date.getDate();
                } else {
                    giorno = +date.getDate();
                }
                let data = anno.toString() + mese + giorno.toString();
                console.log('data servizio',data);

                switch(this.azienda)
                {
                    case 'svs':
                        this.api_url = environment.api_url_svs;
                        break;
                    case 'cts':
                        this.api_url = environment.api_url_cts;
                        break;
                }
                console.log('api url mission service', this.api_url+'Missione/GetList');
                return this.http.post<Missioni>(this.api_url+'Missione/GetList',
                                { MezzoIdentifier: { Targa: tg }, Data: data })
                .subscribe( 
                    async resData => {
                        console.log('resData api GetList', JSON.stringify(resData));
                        
                        const missioni : MissioneModel[] = [];
                        if( resData.Result  )
                        {
                            resData.Missioni.forEach(async element => {
                                // console.log('element', JSON.stringify(element));

                                let PrenotazioneIdentifier = new PrenotazioneIdentifierModel(
                                    element.Prenotazione.Identifier.Esercizio,
                                    element.Prenotazione.Identifier.Numero,
                                    element.Prenotazione.Identifier.NumeroRigaMissione,
                                    element.Prenotazione.Identifier.Registro
                                )
            
                                let PrenotazioneAutomezzoIdentifier = new AutomezzoIdentifierModel(
                                    element.Prenotazione.Automezzo.Identifier.Targa
                                )

                                let PrenotazioneAutomezzo = new AutomezzoBaseModel(
                                    PrenotazioneAutomezzoIdentifier,
                                    element.Prenotazione.Automezzo.Sigla
                                )

                                let ItinerariPrenotazione: ItinerarioModel[] = [];
                                           
                                element.Prenotazione.Itinerari.forEach( item => {

                                    let RichiesteProdotti: RichiestaProdottiModel[] = [];

                                    // console.log('item', JSON.stringify(item));
                                    let ItinerarioIdentifier = new ItinerarioIdentifierModel(
                                        item.Identifier.Esercizio,
                                        item.Identifier.Registro,
                                        item.Identifier.Numero,
                                        item.Identifier.NumeroRigaItinerario
                                    )

                                    let ItinerarioCausale : CausaleTrasportiIdentifierModel;
                                    if( item.CausaleIdentifier )
                                    {
                                        ItinerarioCausale = new CausaleTrasportiIdentifierModel(
                                            item.CausaleIdentifier.Codice
                                        )
                                    }
                                    else
                                    {
                                        ItinerarioCausale = null;
                                    }
                                    
                                    let ItinerarioArticoloIdentifier = new ArticoloIdentifierModel(
                                        item.Articolo.Identifier.Codice
                                    )

                                    let ItinerarioArticolo = new ArticoloBaseModel(
                                        ItinerarioArticoloIdentifier,
                                        item.Articolo.Flann,
                                        item.Articolo.Attivo,
                                        item.Articolo.Barcode,
                                        item.Articolo.Descrizione,
                                        item.Articolo.DescrizioneAggiuntiva
                                    )

                                    let ItinerarioPdiArrivo: PdiBaseModel;     
                            
                                    let ItinerarioPdiPartenza: PdiBaseModel;

                                    let ItinerarioOrarioPartenza: string = '';

                                    let ItinerarioOrarioArrivo: string = '';

                                    let RichiestaProdIdentifier: RichiestaProdottiIdentifier;

                                    let RichiestaProd: RichiestaProdottiModel;

                                    if( item.TipoOperazione === 1 )
                                    {
                                        ItinerarioOrarioPartenza = item.OrarioPartenza;

                                        let ItinerarioPdiPartenzaCoordinate : CoordinateModel;
                                        if( item.PdiPartenza.Coordinate )
                                        {
                                            ItinerarioPdiPartenzaCoordinate = new CoordinateModel(
                                                item.PdiPartenza.Coordinate.Latitudine,
                                                item.PdiPartenza.Coordinate.Longitudine
                                            )
                                        }
                                        else
                                        {
                                            ItinerarioPdiPartenzaCoordinate = null;
                                        }
                                        
                                        let ItinerarioPdiPartenzaIdentifier = new PdiIdentifierModel(
                                            item.PdiPartenza.Identifier.Codice
                                        )

                                        ItinerarioPdiPartenza = new PdiBaseModel(
                                            ItinerarioPdiPartenzaIdentifier,
                                            item.PdiPartenza.Descrizione1,
                                            item.PdiPartenza.Descrizione2,
                                            item.PdiPartenza.Descrizione3,
                                            ItinerarioPdiPartenzaCoordinate
                                        )
                                        ItinerarioPdiArrivo = null;

                                    }        
                                    if( item.TipoOperazione === 2 )
                                    {
                                        ItinerarioOrarioArrivo = item.OrarioArrivo;

                                        let ItinerarioPdiArrivoCoordinate : CoordinateModel;
                                        if( item.PdiArrivo.Coordinate )
                                        {
                                            ItinerarioPdiArrivoCoordinate = new CoordinateModel(
                                                item.PdiArrivo.Coordinate.Latitudine,
                                                item.PdiArrivo.Coordinate.Longitudine
                                            )
                                        }

                                        let ItinerarioPdiArrivoIdentifier = new PdiIdentifierModel(
                                            item.PdiArrivo.Identifier.Codice
                                        )

                                        ItinerarioPdiArrivo = new PdiBaseModel(
                                            ItinerarioPdiArrivoIdentifier,
                                            item.PdiArrivo.Descrizione1,
                                            item.PdiArrivo.Descrizione2,
                                            item.PdiArrivo.Descrizione3,
                                            ItinerarioPdiArrivoCoordinate
                                        )
                                        ItinerarioPdiPartenza = null;
                                    }

                                    item.RichiesteProdotti.forEach(rich => {

                                        // console.log('rich', JSON.stringify(rich));
                                        RichiestaProdIdentifier = new RichiestaProdottiIdentifier(
                                            rich.Identifier.Esercizio,
                                            rich.Identifier.Numero,
                                            rich.Identifier.NumeroRigaItinerario,
                                            rich.Identifier.NumeroRigaMissione,
                                            rich.Identifier.Registro,
                                            rich.Identifier.NumeroRigaStato
                                        )

                                        RichiestaProd = new RichiestaProdottiModel (
                                            RichiestaProdIdentifier,
                                            rich.StatoRichiesta,
                                            rich.DataComunicazione,
                                            rich.OraComunicazione,
                                            rich.Coordinate
                                        )

                                        RichiesteProdotti.push(RichiestaProd);    
                                    })
                                    
                                    let Itinerario = new ItinerarioModel(
                                        ItinerarioIdentifier,
                                        item.Annotazioni,
                                        ItinerarioOrarioPartenza,
                                        ItinerarioOrarioArrivo,
                                        item.Quantita,
                                        ItinerarioCausale,
                                        item.TipoOperazione,
                                        ItinerarioArticolo,
                                        ItinerarioPdiPartenza,
                                        ItinerarioPdiArrivo,
                                        RichiesteProdotti
                                    )

                                    ItinerariPrenotazione.push(Itinerario);
                                })

                                let color_background: string;
                                let color_siren: string;
                                let color_border: string;
                                let arrow_detail: string = 'true';

                                if( element.Prenotazione.StatoRichiesta === 6 || element.Prenotazione.StatoRichiesta === 8)
                                {
                                    color_background = 'success';
                                    color_siren = 'ordinario_bianco';
                                }
                                if( element.Prenotazione.StatoRichiesta === 7)
                                {
                                    color_background = 'warning';
                                    color_siren = 'ordinario';
                                }
                                if( element.Prenotazione.StatoRichiesta === 4)
                                {
                                    color_border = 'border-item-close';
                                    arrow_detail = 'false';
                                }    

                                let Prenotazione = new PrenotazioneModel(
                                    PrenotazioneIdentifier,
                                    element.Prenotazione.Assegnazione,
                                    element.Prenotazione.DataViaggio,
                                    element.Prenotazione.OraViaggio,
                                    element.Prenotazione.StatoRichiesta,
                                    color_background,
                                    color_siren,
                                    color_border,
                                    arrow_detail,
                                    PrenotazioneAutomezzo,
                                    ItinerariPrenotazione
                                )

                                let RichiestaIdentifier = new RichiestaIdentifierModel(
                                    element.Richiesta.Identifier.Esercizio,
                                    element.Richiesta.Identifier.Registro,
                                    element.Richiesta.Identifier.Numero
                                )

                                let RichiestaCausaleIdentifier = new CausaleTrasportiIdentifierModel(
                                    element.Richiesta.Causale.Identifier.Codice
                                )

                                let RichiestaCausale = new CausaleTrasportiBaseModel(
                                    RichiestaCausaleIdentifier,
                                    element.Richiesta.Causale.Descrizione
                                )
            
                                let Richiesta = new RichiestaBaseModel(
                                    RichiestaIdentifier,
                                    element.Richiesta.TipoServizio,
                                    RichiestaCausale,
                                    element.Richiesta.Descrizione
                                )
            
                                let Missione = new MissioneModel(
                                    Prenotazione,
                                    Richiesta
                                )

                                missioni.push(Missione);
        
                            });

                            console.log('missioni prima del salvataggio su db', JSON.stringify(missioni));

                        try{
                            console.log('sono qui');
                            db = await this.SetConnection();
            
                            console.log('database', db.getConnectionDBName());
                            console.log('connection', JSON.stringify(this.sqliteService.isConnection('missioni')));

                            missioni.forEach( async missione => {
                                console.log('missione', JSON.stringify(missione));
                                count_itinerari= missione.Prenotazione.Itinerari.length-1;  
                                console.log('count', count_itinerari);
                                let pdi_partenza = 0;
                                let pdi_arrivo = 0;

                                if( missione.Prenotazione.Itinerari[0].TipoOperazione === 1 )
                                {
                                    pdi_partenza = missione.Prenotazione.Itinerari[0].PdiPartenza.Identifier.Codice;
                                }
                                
                                if( missione.Prenotazione.Itinerari[count_itinerari].TipoOperazione === 1 )
                                {
                                    pdi_arrivo = missione.Prenotazione.Itinerari[count_itinerari].PdiPartenza.Identifier.Codice;
                                }
                                
                                // Testata
                                if( missione.Richiesta.Causale ){
                                    console.log('identifier casusale', missione.Richiesta.Causale.Identifier.Codice);
                                }

                                let causale = '';
                                let causale_descr = '';

                                if( missione.Richiesta.Causale )
                                {
                                    causale = missione.Richiesta.Causale.Identifier.Codice;
                                    causale_descr = missione.Richiesta.Causale.Descrizione;
                                }

                                query = `INSERT INTO testata (esercizio,registro,numero,causale,causale_descr,id_tipomezzo,pdi_partenza,pdi_destinazione,id_automezzo,id_assegnazione,descrizione) VALUES ( '${missione.Richiesta.Identifier.Esercizio}','${missione.Richiesta.Identifier.Registro}',${missione.Richiesta.Identifier.Numero},'${causale}', '${causale_descr}',${missione.Richiesta.TipoServizio},${pdi_partenza},${pdi_arrivo},'${missione.Prenotazione.Automezzo.Identifier.Targa}','${missione.Prenotazione.Assegnazione.substring(0, missione.Prenotazione.Assegnazione.length-3)}','${missione.Richiesta.Descrizione.replace(/'/g, "''")}');`;                            
                                console.log('query insert testata', query);
                                res = await db.execute(query);
                                console.log(`res della insert testata: ${JSON.stringify(res)}`)

                                query = `INSERT INTO testata_prospetto (esercizio,registro,numero,causale,causale_descr,id_tipomezzo,pdi_partenza,pdi_destinazione,id_automezzo,id_assegnazione,descrizione) VALUES ( '${missione.Richiesta.Identifier.Esercizio}','${missione.Richiesta.Identifier.Registro}',${missione.Richiesta.Identifier.Numero},'${causale}', '${causale_descr}',${missione.Richiesta.TipoServizio},${pdi_partenza},${pdi_arrivo},'${missione.Prenotazione.Automezzo.Identifier.Targa}','${missione.Prenotazione.Assegnazione.substring(0, missione.Prenotazione.Assegnazione.length-3)}','${missione.Richiesta.Descrizione.replace(/'/g, "''")}');`;                            
                                console.log('query insert testata_prospetto', query);
                                res2 = await db.execute(query);
                                console.log(`res2 della insert testata_prospetto: ${JSON.stringify(res2)}`)

                                //Prenotazioni
                                query = `INSERT INTO prenotazioni (esercizio,registro,numero,numero_riga,data_viaggio,ora_viaggio,id_automezzo,stato_richiesta,id_assegnazione) VALUES ( '${missione.Richiesta.Identifier.Esercizio}','${missione.Richiesta.Identifier.Registro}',${missione.Richiesta.Identifier.Numero},${missione.Prenotazione.Identifier.NumeroRigaMissione},'${missione.Prenotazione.DataViaggio}','${missione.Prenotazione.OraViaggio}','${missione.Prenotazione.Automezzo.Identifier.Targa}',${missione.Prenotazione.StatoRichiesta},'${missione.Prenotazione.Assegnazione}');`
                                console.log('query insert prenotazioni', query);
                                res3 = await db.execute(query);
                                console.log(`res3 della insert prenotazioni: ${JSON.stringify(res3)}`)

                                query = `INSERT INTO prenotazioni_prospetto (esercizio,registro,numero,numero_riga,data_viaggio,ora_viaggio,id_automezzo,stato_richiesta,id_assegnazione) VALUES ( '${missione.Richiesta.Identifier.Esercizio}','${missione.Richiesta.Identifier.Registro}',${missione.Richiesta.Identifier.Numero},${missione.Prenotazione.Identifier.NumeroRigaMissione},'${missione.Prenotazione.DataViaggio}','${missione.Prenotazione.OraViaggio}','${missione.Prenotazione.Automezzo.Identifier.Targa}',${missione.Prenotazione.StatoRichiesta},'${missione.Prenotazione.Assegnazione}');`
                                console.log('query insert prenotazioni_prospetto', query);
                                res4 = await db.execute(query);
                                console.log(`res4 della insert prenotazioni_prospetto: ${JSON.stringify(res4)}`)
                                
                                //Itinerari
                                let count = 0;
                                missione.Prenotazione.Itinerari.forEach( async it => {
                                    console.log('itinerario', JSON.stringify(it));
                                    let pdi_lat: number;
                                    let pdi_lng: number;
                                    let pdi_descr1: string;
                                    let pdi_descr2: string;
                                    let pdi_descr3: string;
                                    let pdi_codice: number;

                                    if( it.TipoOperazione === 1 )
                                    {
                                        if( it.PdiPartenza.Coordinate ){
                                            pdi_lat = it.PdiPartenza.Coordinate.Latitudine;
                                            pdi_lng = it.PdiPartenza.Coordinate.Longitudine;
                                        }
                                        else
                                        {
                                            pdi_lat = null;
                                            pdi_lng = null;
                                        }
                                        
                                        pdi_descr1 = it.PdiPartenza.Descrizione1;
                                        pdi_descr2 = it.PdiPartenza.Descrizione2;
                                        pdi_descr3 = it.PdiPartenza.Descrizione3;
                                        pdi_codice = it.PdiPartenza.Identifier.Codice;
                                    }

                                    if( it.TipoOperazione === 2 )
                                    {
                                        if( it.PdiArrivo.Coordinate ){
                                            pdi_lat = it.PdiArrivo.Coordinate.Latitudine;
                                            pdi_lng = it.PdiArrivo.Coordinate.Longitudine;
                                        }
                                        else
                                        {
                                            pdi_lat = null;
                                            pdi_lng = null;
                                        }
                                        
                                        pdi_descr1 = it.PdiArrivo.Descrizione1;
                                        pdi_descr2 = it.PdiArrivo.Descrizione2;
                                        pdi_descr3 = it.PdiArrivo.Descrizione3;
                                        pdi_codice = it.PdiArrivo.Identifier.Codice;
                                    }
                                    ++count;
                                    query =`INSERT INTO itinerari (esercizio,registro,numero,numero_riga_itinerario,tipo_operazione,orario_arrivo,orario_partenza,pdi_lat,pdi_lng,pdi_descr1,pdi_descr2,pdi_descr3,pdi_codice,quantita, articolo_barcode,articolo_descrizione,articolo_codice, ordine ) VALUES ('${it.Identifier.Esercizio}','${it.Identifier.Registro}',${it.Identifier.Numero},${it.Identifier.NumeroRigaItinerario},${it.TipoOperazione},'${it.OrarioArrivo}','${it.OrarioPartenza}',${pdi_lat},${pdi_lng},'${pdi_descr1.replace(/'/g, "''")}','${pdi_descr2.replace(/'/g, "''")}','${pdi_descr3.replace(/'/g, "''")}',${pdi_codice},${it.Quantita},'${it.Articolo.Barcode}','${it.Articolo.Descrizione.replace(/'/g, "''")}','${it.Articolo.Identifier.Codice}', ${count});`
                                    console.log('query insert itinerari', query);
                                    res5 = await db.execute(query);
                                    console.log(`res5 della insert itinerari: ${JSON.stringify(res5)}`)

                                    query =`INSERT INTO itinerari_prospetto (esercizio,registro,numero,numero_riga_itinerario,tipo_operazione,orario_arrivo,orario_partenza,pdi_lat,pdi_lng,pdi_descr1,pdi_descr2,pdi_descr3,pdi_codice,quantita, articolo_barcode,articolo_descrizione,articolo_codice, ordine ) VALUES ('${it.Identifier.Esercizio}','${it.Identifier.Registro}',${it.Identifier.Numero},${it.Identifier.NumeroRigaItinerario},${it.TipoOperazione},'${it.OrarioArrivo}','${it.OrarioPartenza}',${pdi_lat},${pdi_lng},'${pdi_descr1.replace(/'/g, "''")}','${pdi_descr2.replace(/'/g, "''")}','${pdi_descr3.replace(/'/g, "''")}',${pdi_codice},${it.Quantita},'${it.Articolo.Barcode}','${it.Articolo.Descrizione.replace(/'/g, "''")}','${it.Articolo.Identifier.Codice}', ${count});`
                                    console.log('query insert itinerari_prospetto', query);
                                    res6 = await db.execute(query);
                                    console.log(`res6 della insert itinerari_prospetto: ${JSON.stringify(res6)}`)

                                    let count_prod = 0;
                                    console.log('richiesta prodotti itinerario', JSON.stringify(it.RichiesteProdotti));
                                    it.RichiesteProdotti.forEach( async rp => {
                                            ++count_prod;
                                            query = `INSERT INTO richiesta_prodotti (esercizio,registro,numero,numero_riga_itinerario,numero_riga_missione,numero_riga_stato,stato,id_prodotto,id_contenitore,id_cella,temperatura,tipo_operazione,data_comunicazione,ora_comunicazione,lat,lng, ordine ) VALUES ('${rp.Identifier.Esercizio}','${rp.Identifier.Registro}',${rp.Identifier.Numero},${rp.Identifier.NumeroRigaItinerario},${rp.Identifier.NumeroRigaMissione},${rp.Identifier.NumeroRigaStato},${rp.StatoRichiesta},NULL,NULL,NULL,NULL,${it.TipoOperazione},'${rp.DataComunicazione}','${rp.OraComunicazione}','${rp.Coordinate.Latitudine}','${rp.Coordinate.Longitudine}', ${count_prod});`;
                                            console.log('query insert richiesta_prodotti', query);
                                            resp = await db.execute(query);
                                            console.log(`res della insert richiesta_prodotti: ${JSON.stringify(resp)}`)
                                        }
                                    )
                                })
                                /* console.log('query', query);
                                res = await db.execute(query);
                                console.log(`res della query generale: ${JSON.stringify(res)}`); */

                                //Memorizzo che ho già caricato i dati, altrimenti ogni volta che si apre la pagina dei dati profilo si ricaricherebbe tutto
                                localStorage.setItem('getdatioffline','1');

                            })

                            // await db.close();
                            /* await this.sqliteService.closeConnection('missioni');
                            console.log('after connection close'); */
                            // console.log(`after db.close`)
                        }    
                        catch (err) {
                            console.log(`Error: ${err}`);
                            errore = true;
                        }  
                    }   
                    else
                    {
                        //Errore
                    }  
                    // this._missioni.next(missioni);
                   /*  await db.close();
                    await this.sqliteService.closeConnection('missioni');
                    console.log('after connection close'); */
                    return missioni;   
                })   
            }) 
        })
  
      // })
    }

    async SetConnection(){
        let conn_present : boolean;
        let db: SQLiteDBConnection;
        /* db = await this.sqliteService.createConnection("missioni",false,"no-encryption", 1);
        await db.open();   */  

        await this.sqliteService.isConnection('missioni')
        .then( async data => 
            { 
                console.log('db connesso????',data.result); 
                conn_present = data.result; 
        
                if( !conn_present )
                {
                    console.log('ramo 1');
                    try{
                        // await this.sqliteService.closeConnection('missioni');
                        console.log('ramo 1 bus');
                        await this.sqliteService.createConnection("missioni",false,"no-encryption", 1).then( async conn => {console.log('retrieve'); db=conn; await db.open(); return db; }); 
                        // await db.open();         
                    }
                    catch{
                        console.log('errore 1');
                        await this.sqliteService.retrieveConnection('missioni').then( async conn => {console.log('retrieve'); db=conn; await db.open(); return db; });  
                        // await db.open();
                    }
                    /* await this.sqliteService.closeConnection('missioni');
                    console.log('ramo 1 bus');
                    db = await this.sqliteService.createConnection("missioni",false,"no-encryption", 1);
                    await db.open();   */       
                }
                else
                {
                    console.log('ramo2');
                    try{
                        await this.sqliteService.createConnection("missioni",false,"no-encryption", 1).then( async conn => {console.log('retrieve'); db=conn; await db.open(); return db; }); 
                        // await db.open();         
                    }
                    catch{
                        console.log('errore 2');
                        await this.sqliteService.retrieveConnection('missioni').then( async conn => {console.log('retrieve'); db=conn; await db.open(); return db; });  
                        
                    }
                    
                    //await this.sqliteService.retrieveConnection('missioni').then( conn => {console.log('retrieve'); db=conn; return db;});
                }
            });
        return db;    
    }

    async resetLocalDatabase( db: SQLiteDBConnection )
    {
        let query: string;
        let res: any;

        query =`DELETE FROM testata`;
        console.log('query delete testata', query);
        res = await db.execute(query);
        console.log(`res della delete testata: ${JSON.stringify(res)}`)

        query =`DELETE FROM prenotazioni`;
        console.log('query delete prenotazioni', query);
        res = await db.execute(query);
        console.log(`res della delete prenotazioni: ${JSON.stringify(res)}`)

        query =`DELETE FROM itinerari`;
        console.log('query delete itinerari', query);
        res = await db.execute(query);
        console.log(`res della delete itinerari: ${JSON.stringify(res)}`)

        query =`DELETE FROM richiesta_prodotti`;
        console.log('query delete richiesta_prodotti', query);
        res = await db.execute(query);
        console.log(`res della delete richiesta_prodotti: ${JSON.stringify(res)}`)

        query =`DELETE FROM testata_prospetto`;
        console.log('query delete testata_prospetto', query);
        res = await db.execute(query);
        console.log(`res della delete testata_prospetto: ${JSON.stringify(res)}`)

        query =`DELETE FROM prenotazioni_prospetto`;
        console.log('query delete prenotazioni_prospetto', query);
        res = await db.execute(query);
        console.log(`res della delete prenotazioni_prospetto: ${JSON.stringify(res)}`)

        query =`DELETE FROM itinerari_prospetto`;
        console.log('query delete itinerari_prospetto', query);
        res = await db.execute(query);
        console.log(`res della delete itinerari_prospetto: ${JSON.stringify(res)}`)

        /* await db.close();
        await this.sqliteService.closeConnection('missioni');
        console.log(`after db.close`) */
    }

    async updateLocalStato(
        esercizio: string,
        registro: string,
        numero: number,
        numerorigamissione: number,
        numerorigaitinerario: number,
        nuovostato: number,
        ordine: number,
        assegnazione: string,
        lng: number,
        lat: number,
        temperatura: string,
        id_prodotto: string,
        id_contenitore: string,
        id_cella: string,
        tipo_operazione: number,
        insert_fifo: boolean){

        let res: any;
        let res2: any;
        let res3: any;
        let res4: any;
        let query: string = '';
        let errore:boolean = false;
        let db : SQLiteDBConnection;    
        let max_riga: number;
        
        let date = new Date();
        let giorno = +date.getDate();
        let gg: string;
        if(giorno < 10){
            gg = '0' + giorno.toString();
        }else{
            gg = giorno.toString();
        }
        let h: string;
        let ora = +date.getHours();
        if(ora < 10){
            h = '0' + ora.toString();
        }else{
            h = ora.toString();
        }
        let m: string;
        let minuti= +date.getMinutes();
        if(minuti < 10){
            m = '0' + minuti.toString();
        }else{
            m = minuti.toString();
        }
        let mese: string;
        if ((+date.getMonth() + 1) < 10) {
            mese = '0' + (date.getMonth() + 1).toString();
        } else {
            mese = (date.getMonth() + 1).toString();
        }

        let data = date.getFullYear().toString() + mese + gg;

        let time = h + '.' + m;

        let Coordinate = '{ "Latitudine": ' +  lat  + ' ,Longitudine:' + lng + '}';
        
        let post_params = `{ ItenirarioIdentifier: { NumeroRigaItinerario: '` + numerorigaitinerario  + `', NumeroRigaMissione: '` + numerorigamissione + `', Esercizio: '` + esercizio + `', Registro: '` + registro + `',Numero: ` + numero + `}, NuovoStato: ` + nuovostato + `, Data: '` + data + `', Ora: '`+ time + `', Coordinate: '` + Coordinate + `'}`;
        switch(this.azienda)
        {
        case 'svs':
            this.api_url = environment.api_url_svs;
            break;
        case 'cts':
            this.api_url = environment.api_url_cts;
            break;
        }
        let url = this.api_url+'Missione/SetStatoMissione';
        let type_call = 'post';
        
        try 
        {
            db = await this.SetConnection();

            if( insert_fifo )
            {
                query = `INSERT INTO rest_service_fifo ( url_method, type, params, headers, data, ora ) VALUES ( '${url}', '${type_call}', '${post_params}', NULL, '${data}', '${time}')`;
                console.log('query insert rest_service_fifo', query);
                res = await db.execute(query);
                console.log(`res della insert rest_service_fifo: ${JSON.stringify(res)}`)
            }
            
            //Aggiornamento stato richiesta missione solo per gli stati 6,7,8,4
            if( nuovostato === 6 || nuovostato === 7 || nuovostato ===8 || nuovostato === 4 )
            {
                query = `UPDATE prenotazioni SET stato_richiesta=${nuovostato} WHERE esercizio='${esercizio}' AND registro='${registro}' AND numero=${numero} AND numero_riga=${numerorigamissione}`;
                console.log('query update prenotazioni stato_richiesta', query);
                res2 = await db.query(query);
                console.log(`res2 della update prenotazioni stato_richiesta: ${JSON.stringify(res2)}`)
            }
            else
            {
                //Si ricava l'ultimo numero_riga_stato
                query = `SELECT MAX(numero_riga_stato) as max_riga_stato FROM richiesta_prodotti WHERE esercizio='${esercizio}' AND registro='${registro}' AND numero=${numero} AND numero_riga_itinerario=${numerorigaitinerario} AND numero_riga_missione=${numerorigamissione}`;
                console.log('query select max numero_riga_stato in richiesta_prodotti', query);
                res4 = await db.query(query);
                console.log(`res4 della insert richiesta_prodotti: ${JSON.stringify(res4)}`)
                console.log('risultato select max numero_riga_stato in richiesta_prodotti',JSON.stringify(res4.values));

                let max: number = 0;
                res4.values.forEach( item => {
                    if( !!item.max_riga_stato ){
                        console.log('incremento max');
                        max = item.max_riga_stato;
                        max += 1;
                    }
                    else{
                        console.log('max 1');
                        max = 1;
                    }
                })
                
                console.log('max',max);

                console.log('nuovo stato', nuovostato);
                query = `INSERT INTO richiesta_prodotti (esercizio,registro,numero,numero_riga_itinerario,numero_riga_missione,numero_riga_stato,stato, id_prodotto,id_contenitore,id_cella,temperatura,tipo_operazione,data_comunicazione,ora_comunicazione,lat,lng,ordine) VALUES ('${esercizio}','${registro}',${numero},${numerorigaitinerario},${numerorigamissione},${max},${nuovostato},'${id_prodotto}','${id_contenitore}','${id_cella}','${temperatura}', ${tipo_operazione},'${data}','${time}','${lat}','${lng}',${ordine})`;
                console.log('query insert richiesta_prodotti', query);
                res3 = await db.execute(query);
                console.log(`res3 della insert richiesta_prodotti: ${JSON.stringify(res3)}`)
            }

            // await db.close();
            /* await this.sqliteService.closeConnection('missioni');
            console.log(`after db.close`); */
        }
        catch{
            //Errore



        }    

    }
    
    async runFifoService(){

        let db: SQLiteDBConnection;
        let query: string;
        let res: any;
        let rese: any;

        db = await this.SetConnection();

        query = "SELECT * FROM rest_service_fifo ORDER BY data,ora";

        console.log('query select rest_service_fifo', query);
        res = await db.query(query);
        console.log(`res della select rest_service_fifo: ${JSON.stringify(res)}`)

        res.values.forEach( item => {

            if( item.url_metodo.includes('SetStatoMissione') )
            {
                this.http.post<resultSetStatoMissione>(item.url_metodo, item.params )
                .pipe(
                    map(async data => { 
                        console.log('data set stato', data);  
                        if(data.Result){
                            //Eliminiamo la riga dalla tabella 
                            query = `DELETE FROM rest_service_fifo WHERE url_metodo='${item.url_metodo}' AND params='${item.params}' AND data='${item.data}' AND ora='${item.ora}'`
                            console.log('query delete rest_service_fifo', query);
                            rese = await db.execute(query);
                            console.log(`rese della delete rest_service_fifo: ${JSON.stringify(rese)}`)
                        }
                    })
                );
            }
            else{
                if( item.url_metodo.includes('temperature') ){
                    try {
                        return this.http
                            .get<any>(
                                    `${item.url_metodo}${item.params},
                                    { headers: ${item.headers} }`
                                )
                                .pipe(
                                    map( async resData => {
                                        console.log('resData success', resData.success);
                                        console.log('resData message', resData.message);
                                        if(resData.success){
                                            //Eliminiamo la riga dalla tabella 
                                            query = `DELETE FROM rest_service_fifo WHERE url_metodo='${item.url_metodo}' AND params='${item.params}' AND data='${item.data}' AND ora='${item.ora}'`
                                            console.log('query delete rest_service_fifo', query);
                                            rese = await db.execute(query);
                                            console.log(`rese della delete rest_service_fifo: ${JSON.stringify(rese)}`)
                                        }
                                    })
                                );
                    }
                    catch (error) {
                        console.error(error);
                    /* let toast = await this.toastCtrl.create({ message: error , duration: 10000, position: 'bottom' });
                    toast.present(); */
                    }
                }
            }
            
        })    

        // await db.close();
        await this.sqliteService.closeConnection('missioni');
        console.log(`after db.close`);
    }
}
