import { SafeUrl } from '@angular/platform-browser';
import { CoordinateModel } from './../missioni/missione-itinerario.model';

/* export enum StatiAnagraficaUtente
 {
  NonSpecificato = 0,
  Inserito = 1,
  Attivo = 2,
  Disabilitato = 3
 }

 export enum Sesso
 {
  NonSpecificato = 0,
  Maschio = 1,
  Femmina = 2
 } */

export enum TipoOperatore
{
    Non_Specificato = 0,
    Dipendente,
    Collaboratore_Esterno,
    Volontario,
    Servizio_Civile,
    In_Affidamento,
    Tirocinante
}

enum TipoPatente
{
    NS = 0, // Non specificato
    A,
    B,
    BE,
    C,
    CE,
    D,
    DE
}

export enum TipoRecapito
{
    NonSpecificato = 0,
    TelefonoFisso = 1,
    Cellulare = 2,
    Fax = 3,
    Email = 4,
    SitoWeb = 5,
    Altro = 6,
    PaginaFacebook = 7,
    PaginaTwitter = 8
}

export class StatiAnagraficaUtente {
    constructor(
        public Stato: string
    ){}    
  }
  
  export class Sesso {
      constructor(
        public sesso: string
      ){}  
  }
  
  export class CredenzialiUtenteModel {
      constructor(
        public Email: string,
        public Password: string
      ){}  
  }
  
  export class DatiFiscaliModel {
      constructor(
        public PartitaIVA: string,
        public CodiceFiscale: string
      ){}  
  }
  
  export class StradarioIdentifierModel {
    constructor(
        public ID: number
    ){}
  }

  export class ProvinciaModel{
    constructor(
      public Sigla: string,
      public Denominazione: string
    ){}
  }

  export class ComuneModel{
    constructor(
      public CodiceCastale: string,
      public Denominazione: string
    ){}
  }

  export class NazioneModel{
    constructor(
      public CodiceIstat: string,
      public Denominazione: string
    ){}
  }

  export class StradarioModel {
    constructor(
      public StradarioIdentifier: StradarioIdentifierModel,
      public Specifica: string,
      public Descrizione: string,
      public CAP: string,
      public Localita: string,
      public Comune: ComuneModel,
      public Provincia: ProvinciaModel
    ){}
  }

  export class IndirizzoModel{
    constructor(
      public StradarioIdentifier: StradarioIdentifierModel,
      public Indirizzo: string,
      public Civico: string,
      public CAP: string,
      public Localita: string,
      public Comune: ComuneModel,
      public Provincia: ProvinciaModel,
      public Nazione: NazioneModel,
      public Coordinate: CoordinateModel
    ){}
  }

  export class RecapitoIdentifierModel {
    constructor(
      public ID: number
    ){}
  }

  export class RecapitoBaseModel {
    constructor(
      public Identifier: RecapitoIdentifierModel,
      public Descrizione: string,
      public Prefisso: string,
      public Telefono: string,
      public TipoRecapito: TipoRecapito
    ){}
  }

  export class SezioneIdentifierModel{
    constructor(
      public ID: string
    ){}
  }
  
  export class SezioneBaseModel{
    constructor(
      public Identifier: SezioneIdentifierModel,
      public Descrizione: string,
      public SezioneDefault: boolean,
      public Indirizzo: IndirizzoModel,
      public DistanzaMinimaPresenza: Number
    ){}
  }

  export class SezioneUtenteModel{
    constructor(
      public Predefinita: boolean
    ){}
  }

  export class OperatoreIdentifierModel{
    constructor(
      public ID: number
    ){}
  }

  export class OperatoreBaseModel {
    constructor(
      public OperatoreIdentifier: OperatoreIdentifierModel,
      public Badge: string,
      public Matricola: string,
      public DataInizioAttivita: string,
      public DataTermineAttivita: string,
      public Sezione: SezioneBaseModel,
      //public SezioneIdentifier: SezioneIdentifierModel,
      public Tipologia: TipoOperatore,
      public Annotazione: string,
      public Patente: PatenteModel
    ){}
  }

  export class PatenteModel{
    constructor(
      public Patente: TipoPatente,
      public PatenteNumero: string,
      public PatenteScadenzaData: string
    ){}
  }

  export class AnagraficaUtenteIdentifierModel {
      constructor(
        public ID: number
      ){}  
  }
  
  export class AnagraficaUtenteBaseModel {
      constructor(
        public Identifier: AnagraficaUtenteIdentifierModel,
        public Credenziali: CredenzialiUtenteModel,
        public Nome: string,
        public Cognome: string,
        public Stato: StatiAnagraficaUtente,
        public DataNascita: string,
        public Sesso: Sesso,
        public ProvinciaNascita: ProvinciaModel,
        public ComuneNascita: ComuneModel,
        public Residenza: IndirizzoModel,
        public Domicilio: IndirizzoModel,
        public DatiFiscali: DatiFiscaliModel,
        public Recapiti: RecapitoBaseModel[]
      ){}   
  }

  export class AnagraficaUtenteModel extends  AnagraficaUtenteBaseModel {
    constructor(
        public Identifier: AnagraficaUtenteIdentifierModel,
        public Credenziali: CredenzialiUtenteModel,
        public Nome: string,
        public Cognome: string,
        public Stato: StatiAnagraficaUtente,
        public DataNascita: string,
        public Sesso: Sesso,
        public ProvinciaNascita: ProvinciaModel,
        public ComuneNascita: ComuneModel,
        public Residenza: IndirizzoModel,
        public Domicilio: IndirizzoModel,
        public DatiFiscali: DatiFiscaliModel,
        public Recapiti: RecapitoBaseModel[],
        public OperatoreTrasporti: boolean,
        public OperatoreGenerico: boolean,
        public Operatore: OperatoreBaseModel,
        public Immagine: string,
        public ImmagineSafe: SafeUrl
    ){
      
      super(Identifier,Credenziali,Nome,Cognome,Stato,DataNascita,Sesso,ProvinciaNascita,ComuneNascita,Residenza,Domicilio,DatiFiscali,Recapiti);
   
    }
  }
