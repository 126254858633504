import { environment } from './../../environments/environment';
import { AuthenticateModel } from './auth.model';
import { LoadingController, AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoading = false;
  private _user = new BehaviorSubject<User>(null);

  get userIsAuthenticated() {
    return  this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.JWTToken;
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.userid;
        } else {
          return null;
        }
      })
    );
  }

  get username() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.utente;
        } else {
          return null;
        }
      })
    );
  }

  get nome_cognome() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.nome + ' ' + user.cognome;
        } else {
          return null;
        }
      })
    );
  }

  get JWTToken() {
    // eslint-disable-next-line no-underscore-dangle
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.JWTToken;
        } else {
          return null;
        }
      })
    );
  }

  get refreshToken() {
    // eslint-disable-next-line no-underscore-dangle
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.RefreshToken;
        } else {
          return null;
        }
      })
    );
  }
 
  constructor(
    private http: HttpClient,
    private router: Router,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {}
    
    password: string = '';
    utente: string = '';
    azienda: string = localStorage.getItem('azienda');
    private mySubjectLogout = new Subject<any>();
    obLogout = this.mySubjectLogout.asObservable();
    private api_url: string = '';
         
    serviceLogout(value:any) {
      this.mySubjectLogout.next(value);
    }  

    private mySubjectMaterialeSanificazione = new Subject<any>();
    private mySubjectMateriale = new Subject<any>();
    obMaterialeSanificazione = this.mySubjectMaterialeSanificazione.asObservable();
    obMateriale = this.mySubjectMateriale.asObservable();
  
    serviceMaterialeSanificazione(value:any) {
      this.mySubjectMaterialeSanificazione.next(value);
    }  
    serviceMateriale(value:any) {
      value = false;
      if( this.azienda === 'svs'){
        value = true;
      }
      this.mySubjectMateriale.next(value);
    }  
    
   autoLogin() {

    if (localStorage.getItem('password') !== '' && localStorage.getItem('password') !== null) {
      this.password = localStorage.getItem('password');
    }
    if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
      this.utente = localStorage.getItem('utente');
    }

    console.log('autologin utente', this.utente);
    console.log('autologin password', this.password);

    try {
      return from(this.utente).pipe(
        map(userData => {
          if (!userData || userData === '') {
            return null;
          }

          this.isLoading = true;
          //Leggiamo le credenziali e verifichiamo se sono esistenti
          if (this.utente !== '' && this.utente !== null && this.password !== '' && this.password !== null) {
            this.loadingCtrl
              .create({ keyboardClose: true, message: 'Login in corso...' })
              .then(loadingEl => {
                loadingEl.present();
                let authObs: Observable<AuthenticateModel>;
                authObs = this.login(this.utente, this.password, this.azienda);
                authObs.subscribe(
                  resData => {
                    //Salvataggio in locale storage dei dati del Token
                    localStorage.setItem('JWTToken', resData.Token.JWTToken);
                    localStorage.setItem('RefreshToken', resData.Token.RefreshToken);
                    localStorage.setItem('ID', resData.AnagraficaUtente.Identifier.ID.toString());

                    this.serviceLogout(true);

                    if( this.utente.toLowerCase() === 'fcarnovale' || this.utente.toLowerCase() === 'mcolini' 
                        || this.utente.toLowerCase() === 'fmalara' || this.utente.toLowerCase() === 'scasentini' ) 
                    {
                      if( this.azienda === 'svs' ){
                        this.serviceMaterialeSanificazione(true);
                      }                   
                      else{
                        this.serviceMateriale(false);
                      }  
                    } 
                    else{
                      this.serviceMaterialeSanificazione(false);
                    }

                    if( this.azienda === 'svs' ){
                      this.serviceMateriale(true);
                    }                   
                    else{
                      this.serviceMateriale(false);
                    }

                    if (resData) {
                      this.isLoading = false;
                      loadingEl.dismiss();
                      this.router.navigateByUrl('/dati');
                    }
                  },
                  errRes => {
                    loadingEl.dismiss();
                    this.showAlert(errRes);
                  }
                );
              });
          }else {
            this.showAlert('Utente e/o password errati.');
          }
        })
      );
    } catch {
      if (localStorage.getItem('targa') !== '' && localStorage.getItem('targa') !== null) {
        localStorage.removeItem('targa');
      }
      if (localStorage.getItem('ID') !== '' && localStorage.getItem('ID') !== null) {
        localStorage.removeItem('ID');
      }
      
      this.serviceLogout(false);
      this.serviceMaterialeSanificazione(false);

      this.router.navigateByUrl('/auth');
    }
  }
 
  login(utente: string, password: string, azienda: string) {

    console.log('azienda', azienda);
    localStorage.setItem('azienda', azienda);
    switch(azienda)
    {
      case 'svs':
        this.api_url = environment.api_url_svs;
        break;
      case 'cts':
        this.api_url = environment.api_url_cts  ;
        break;
    }
    
    console.log('api url', this.api_url);
    let response = this.http
    .post<AuthenticateModel>(
      this.api_url + 'login/authenticate',
        { email: utente, password: password }
    )
    .pipe(
      tap(this.setUserData.bind(this)));

    return response;  
  }

  logout() {

    if (localStorage.getItem('targa') !== '' && localStorage.getItem('targa') !== null) {
      localStorage.removeItem('targa');
    }
    if (localStorage.getItem('targhe') !== '' && localStorage.getItem('targhe') !== null) {
      localStorage.removeItem('targhe');
    }
    if (localStorage.getItem('ID') !== '' && localStorage.getItem('ID') !== null) {
      localStorage.removeItem('ID');
    }
    if (localStorage.getItem('immagine') !== '' && localStorage.getItem('immagine') !== null) {
      localStorage.removeItem('immagine');
    }
    if (localStorage.getItem('getdatioffline') !== '' && localStorage.getItem('getdatioffline') !== null) {
      localStorage.removeItem('getdatioffline');
    }
    
    this._user.next(null);

    this.serviceLogout(false);
    this.serviceMaterialeSanificazione(false);
        
    this.router.navigateByUrl('/auth');
  }

  ngOnDestroy() {
  }

  private setUserData(userData: AuthenticateModel) {
    const user = new User(
      userData.AnagraficaUtente.Identifier.ID,
      userData.AnagraficaUtente.Credenziali.Email,
      userData.AnagraficaUtente.Nome,
      userData.AnagraficaUtente.Cognome,
      userData.AnagraficaUtente.Sesso.sesso,
      userData.AnagraficaUtente.Stato.Stato,
      userData.Token.JWTToken,
      userData.Token.RefreshToken
    );
    this._user.next(user);
  }

  private showAlert(message: string) {
    this.alertCtrl
      .create({
        header: 'Autenticazione fallita',
        message: message,
        buttons: [
          { text: 'Okay',
            handler: () => {
              this.router.navigateByUrl('/auth');
            }
          }
        ],
      })
      .then(alertEl => alertEl.present());
  }

  
}
