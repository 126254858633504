export class ProspettoMissioni {
    constructor(
        public viaggio: string,
        public data: Date,
        public ora: string,
        public pd1: string,
        public pd2: string,
        public pd3: string,
        public mezzo: number,
        public id_operazione: number,
        public operazione: string,
        public orario: string,
        public articolo: string,
        public articolo_desc: string,
        public articolo_barcode: string,
        public pdi1: string,
        public pdi2: string,
        public pdi3: string,
        public esercizio: number,
        public registro: string,
        public numero: number,
        public riga: number,
        public idpdi: string,
        public itinerario: number,
        public stato: number
    ) { }
}

export class Prospetto {
    constructor(
        public targa: string,
        public missioni: ProspettoMissioni[],
        public success: boolean,
        public message: string
    ) {}
}