import { Platform } from '@ionic/angular';
import { DatiService } from './dati.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, last } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatiResolverService implements Resolve <Observable<any>>{

  constructor(private ds: DatiService, private platform: Platform) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    console.log('resolve', localStorage.getItem('azienda'));
    //MONICA DA RIPRISTINARE
    /* if( localStorage.getItem('networkok') === '1' || this.platform.is('desktop') )
    { */
      console.log('caricamento dati profilo');
      return this.ds.getDati(parseInt(localStorage.getItem('ID')))
      .pipe(
        last(),
        map(userdata =>  userdata )
      )
    // }
    
  } 
 
}