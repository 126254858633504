import { Platform } from '@ionic/angular';
import { ProspettoService } from './prospetto.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProspettoResolverService implements Resolve<Observable<any>>{

    constructor(private ps: ProspettoService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        const today = new Date();
        const tomorrow = new Date(today.setDate(today.getDate() + 1));
        let platform: Platform;

        let day = +tomorrow.getDate();
        let gg: string;
        if (day < 10) {
            gg = '0' + day.toString();
        } else {
            gg = day.toString();
        }
        let data = gg + '' + (+tomorrow.getMonth() + 1).toString() + '' + tomorrow.getFullYear().toString();
 
        return this.ps.fetchProspettoWeb(data, '0').pipe(
            take(1),
            map(dati => dati)
        )
    }
}