import { DatiResolverService } from './dati/dati-resolver.service';
import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProspettoResolverService } from './prospetto/prospetto-resolver.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dati',
    pathMatch: 'full'
  },
  {
    path: 'missioni',
    loadChildren: () => import('./missioni/missioni.module').then( m => m.MissioniPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./auth/registrazione/registrazione.module').then( m => m.RegistrazionePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'dati',
    loadChildren: () => import('./dati/dati.module').then( m => m.DatiPageModule),
    canLoad: [AuthGuard],
    resolve: {
      myData: DatiResolverService
    },
  },
  {
    path: 'informazioni',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'richieste',
    loadChildren: () => import('./richieste/richieste.module').then( m => m.RichiestePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then( m => m.TicketPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'prospetto',
    loadChildren: () => import('./prospetto/prospetto.module').then( m => m.ProspettoPageModule),
    canLoad: [AuthGuard],
    resolve: {
      myProspetto: ProspettoResolverService
    }
  },
  {
    path: 'richieste-sanificazioni',
    loadChildren: () => import('./richieste-sanificazioni/richieste-sanificazioni.module').then( m => m.RichiesteSanificazioniPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'cambia-password',
    loadChildren: () => import('./cambia-password/cambia-password.module').then( m => m.CambiaPasswordPageModule)
  },

   /*{
    path: 'segnalazioni',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
