import { AlertController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { Prospetto, ProspettoMissioni } from './prospetto.model';
import { AuthService } from '../auth/auth.service';
import { map, tap } from 'rxjs/operators';
import { DatiService } from '../dati/dati.service';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { AutomezzoBaseModel } from '../missioni/missione-itinerario.model';
import { SqliteService } from '../sqlite.service';
import { DetailService } from '../detail.service';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { MissioniService } from '../missioni/missioni.service';

interface MissioniData {
    viaggio: string;
    data: string;
    ora: string;
    pd1: string;
    pd2: string;
    pd3: string;
    mezzo: number;
    stato: number;
}

interface ProspettoData {
    targa: string;
    missioni: MissioniData[];
    success: boolean;
    message: string;
}

interface ProspettoDataDettaglio {
    targa: string;
    missioni: DettaglioData[];
    success: boolean;
    message: string;
}

interface DettaglioData {
  viaggio: string;
  data: string;
  ora: string;
  pd1: string;
  pd2: string;
  pd3: string;
  mezzo: number;
  id_operazione: number;
  operazione: string;
  orario: string;
  articolo: string;
  articolo_desc: string;
  articolo_barcode: string;
  pdi1: string;
  pdi2: string;
  pdi3: string;
  esercizio: number;
  registro: string;
  numero: number;
  riga: number;
  idpdi: string;
  itinerario: number;
  stato: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProspettoService {
    utente: string;
    sezione_dati: string;
    sezione: string;
    appVersion: string;
    codice: number;
    tot: number;
    private _prospetto = new BehaviorSubject<Prospetto[]>([]);
    private _automezzi = new BehaviorSubject<AutomezzoBaseModel[]>([]);
    
    get prospetto() {
        // eslint-disable-next-line no-underscore-dangle
        return this._prospetto.asObservable();
    }

    get automezzi() {
        return this._automezzi.asObservable();
    }

    constructor(private authService: AuthService, 
                private http: HttpClient, 
                private datiService: DatiService, 
                private alertCtrl: AlertController,
                private sqliteService: SqliteService,
                private detailService: DetailService,
                private missioniService: MissioniService,
                private platform: Platform) { }

    fetchProspettoWeb(giorno: string, visione: string) {
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            this.utente = localStorage.getItem('utente');
        }else{
            this.authService.username.subscribe(username => {
                this.utente = username;
            });
        }

        this.datiService.sezione.subscribe(cod => {
            this.sezione_dati = cod;
        });

        if (this.sezione_dati.toLowerCase().includes('firenze')) {
            this.sezione = 'FI';
        }
        if (this.sezione_dati.toLowerCase().includes('livorno') || this.sezione_dati.toLowerCase().includes('elba') || this.sezione_dati.toLowerCase().includes('svs')) {
            this.sezione = 'LI';
        }
        if (this.sezione_dati.toLowerCase().includes('massa')) {
            this.sezione = 'MS';
        }
        if (this.sezione_dati.toLowerCase().includes('pisa')) {
            this.sezione = 'PI';
        }

        if (Capacitor.isPluginAvailable('App')) {
            App.getInfo().then(info => {
                this.appVersion = info.version;
                return this.appVersion;
            },
                err => {
                    this.appVersion = 'web';
                });
        } else {
            this.appVersion = 'web';
        }

        this.codice = parseInt(localStorage.getItem('ID'));
        
        const prospetto = [];

        if( (localStorage.getItem('networkok') && localStorage.getItem('networkok') === '1') || this.platform.is('desktop') ){
            return this.http
            .get<{[key: number]: ProspettoData}>(
                `https://demo.trasportosangueorgani.it/ws/prospetto.php?username=${this.utente}&sede=${this.sezione}&versione=${this.appVersion}&codice=${this.codice}&data=${giorno}&visualizzato=${visione}`,
                {headers: {skip: 'true'}}
            ).pipe(
                map(resData => {
                    //console.log(resData);
                    // const prospetto = [];
                    for(const key in resData){
                        const missioni = [];
                        if(resData.hasOwnProperty(key)){
                            for(const index in resData[key].missioni){
                                missioni.push(new ProspettoMissioni(
                                    resData[key].missioni[index].viaggio,
                                    new Date(resData[key].missioni[index].data),
                                    resData[key].missioni[index].ora,
                                    resData[key].missioni[index].pd1,
                                    resData[key].missioni[index].pd2,
                                    resData[key].missioni[index].pd3,
                                    resData[key].missioni[index].mezzo,
                                    0,
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    0,
                                    '',
                                    0,
                                    0,
                                    '',
                                    0,
                                    resData[key].missioni[index].stato
                                ));
                            }

                            if(resData[0].success === true){
                                prospetto.push(
                                    new Prospetto(
                                        resData[key].targa,
                                        missioni,
                                        resData[key].success,
                                        resData[key].message
                                    )
                                );
                            }else{
                                prospetto.push(
                                    new Prospetto(
                                        "",
                                        [],
                                        resData[key].success,
                                        resData[key].message
                                    )
                                );
                            }
                        }
                    }
                    return prospetto;
                }),
                tap(prosp => {
                    this._prospetto.next(prosp);
                })
            );
        }
       /*  else{
            this._prospetto.next(prospetto);
            return prospetto;
        } */
        
    }

    async fetchProspetto(giorno: string):Promise<Prospetto[]>{

        console.log('fetchProspetto');
        this.datiService.sezione.subscribe(cod => {
            this.sezione_dati = cod;
        });
        console.log('dati sezione', this.sezione_dati);
        if (this.sezione_dati.toLowerCase().includes('firenze')) {
            this.sezione = 'FI';
        }
        if (this.sezione_dati.toLowerCase().includes('livorno') || this.sezione_dati.toLowerCase().includes('elba') || this.sezione_dati.toLowerCase().includes('svs')) {
            this.sezione = 'LI';
        }
        if (this.sezione_dati.toLowerCase().includes('massa')) {
            this.sezione = 'MS';
        }
        if (this.sezione_dati.toLowerCase().includes('pisa')) {
            this.sezione = 'PI';
        }

        if (Capacitor.isPluginAvailable('App')) {
            App.getInfo().then(info => {
                this.appVersion = info.version;
                return this.appVersion;
            },
                err => {
                    this.appVersion = 'web';
                });
        } else {
            this.appVersion = 'web';
        }

        this.codice = parseInt(localStorage.getItem('ID'));

        let query : string = '';
        let res: any;
        console.log(`going to create a connection fetchProspetto`)
        let prospetto : Prospetto[] = [];

        if( localStorage.getItem('targhe') && localStorage.getItem('targhe') !== '' )
        {
            try 
            {
                let db: SQLiteDBConnection;

                db = await this.missioniService.SetConnection();

                query = `SELECT T.*, p.numero_riga, p.data_viaggio, p.ora_viaggio, p.stato_richiesta, p.id_automezzo FROM testata_prospetto AS T INNER JOIN prenotazioni_prospetto AS P on P.esercizio=T.esercizio and P.REGISTRO=T.REGISTRO and P.NUMERO=T.NUMERO  ORDER BY t.id_automezzo, p.ora_viaggio`;
                console.log('query select testata + prenotazioni + itinerari per prospetto', query);
                res = await db.query(query);
                console.log(`res della select testata + prenotazioni + itinerari  per prospetto: ${JSON.stringify(res)}`)
                
                console.log('risultato query prospetto oggi',JSON.stringify(res.values));
        
                let prev_targa: string = '';
                let missioni: ProspettoMissioni[] = [];
                // let prospetto : Prospetto[] = [];
                if( JSON.stringify(res.values).length > 0 )
                {
                    res.values.forEach( item => {
                        console.log('item prospetto',JSON.stringify(item));
                        if( item.id_automezzo !== prev_targa || prev_targa === '' ){
                            if( missioni && missioni.length > 0 ){
                                console.log('missioni valorizzata');
                                prospetto.push(
                                    new Prospetto(
                                        prev_targa,
                                        missioni,
                                        true,
                                        ''
                                    )
                                );
                                missioni = [];
                            }
                        }    
                        missioni.push(
                            new ProspettoMissioni(
                            item.viaggio,
                            item.data_viaggio,
                            item.ora_viaggio,
                            item.causale_descr,
                            item.descrizione,
                            'PARTENZA DALLA SEDE ALLE ORE ' + item.ora_viaggio,
                            item.id_tipomezzo,
                            0,
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            0,
                            '',
                            0,
                            0,
                            '',
                            0,
                            item.stato_richiesta
                            )
                        )

                        prev_targa = item.id_automezzo;
                    })    
                }
                console.log('fine foreach');
                if( missioni && missioni.length > 0 ){
                    console.log('ultima aggiunta');
                    prospetto.push(
                        new Prospetto(
                            prev_targa,
                            missioni,
                            true,
                            ''
                        )
                    );
                    missioni = [];
                }
                console.log('prospetto offline', JSON.stringify(prospetto));

                /* this._prospetto.next(prospetto);
                console.log('_prospetto offline', JSON.stringify(this._prospetto)); */

                /* await db.close();
                await this.sqliteService.closeConnection('missioni');
                console.log(`after db.close`);  */
                            
                console.log('prospetto offline prima del return', JSON.stringify(prospetto));
                return prospetto;
            }
            catch{
                //errore


            }
        }
        else{
            console.log('non ci sono targhe');
            this._prospetto.next(prospetto);
            return prospetto;
        }
            
    }

    getMissioneProspetto(giorno: string, barcode: string){
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            this.utente = localStorage.getItem('utente');
        } else {
            this.authService.username.subscribe(username => {
                this.utente = username;
            });
        }

        this.datiService.sezione.subscribe(cod => {
            this.sezione_dati = cod;
        });

        if (this.sezione_dati.toLowerCase().includes('firenze')) {
            this.sezione = 'FI';
        }
        if (this.sezione_dati.toLowerCase().includes('livorno') || this.sezione_dati.toLowerCase().includes('elba') || this.sezione_dati.toLowerCase().includes('svs')) {
            this.sezione = 'LI';
        }
        if (this.sezione_dati.toLowerCase().includes('massa')) {
            this.sezione = 'MS';
        }
        if (this.sezione_dati.toLowerCase().includes('pisa')) {
            this.sezione = 'PI';
        }

        if (Capacitor.isPluginAvailable('App')) {
            App.getInfo().then(info => {
                this.appVersion = info.version;
                return this.appVersion;
            },
                err => {
                    this.appVersion = 'web';
                });
        } else {
            this.appVersion = 'web';
        }

        this.codice = parseInt(localStorage.getItem('ID'));

        return this.http
            .get<{ [key: number]: ProspettoDataDettaglio }>(
            `https://demo.trasportosangueorgani.it/ws/prospetto.php?username=${this.utente}&sede=${this.sezione}&versione=${this.appVersion}&codice=${this.codice}&data=${giorno}&viaggio=${barcode}`,
            { headers: { skip: 'true' } }
        ).pipe(
            map(resData => {
                //console.log("TRY",resData);
                const prospetto = [];
                for (const key in resData) {
                    //console.log(resData[key]);
                    if (resData.hasOwnProperty(key)) {
                        const missioni = [];
                        for (const index in resData[key].missioni) {
                            let anno = resData[key].missioni[index].data;
                            let mese = resData[key].missioni[index].data;
                            let giorno = resData[key].missioni[index].data;
                            anno = anno.substring(0, 4);
                            mese = mese.substring(4, 2);
                            giorno = giorno.substring(6, 2);
                            let date = anno.toString() + '-' + mese.toString() + '-' + giorno.toString();
                            missioni.push(new ProspettoMissioni(
                                resData[key].missioni[index].viaggio,
                                new Date(date),
                                resData[key].missioni[index].ora,
                                resData[key].missioni[index].pd1,
                                resData[key].missioni[index].pd2,
                                resData[key].missioni[index].pd3,
                                resData[key].missioni[index].mezzo,
                                resData[key].missioni[index].id_operazione,
                                resData[key].missioni[index].operazione,
                                resData[key].missioni[index].orario,
                                resData[key].missioni[index].articolo,
                                resData[key].missioni[index].articolo_desc,
                                resData[key].missioni[index].articolo_barcode,
                                resData[key].missioni[index].pdi1,
                                resData[key].missioni[index].pdi2,
                                resData[key].missioni[index].pdi3,
                                resData[key].missioni[index].esercizio,
                                resData[key].missioni[index].registro,
                                resData[key].missioni[index].numero,
                                resData[key].missioni[index].riga,
                                resData[key].missioni[index].idpdi,
                                resData[key].missioni[index].itinerario,
                                resData[key].missioni[index].stato
                            ));
                        }

                        if (resData[0].success === true) {
                            prospetto.push(
                                new Prospetto(
                                    resData[key].targa,
                                    missioni,
                                    resData[key].success,
                                    resData[key].message
                                )
                            );
                        } else {
                            prospetto.push(
                                new Prospetto(
                                    "",
                                    [],
                                    resData[key].success,
                                    resData[key].message
                                )
                            );
                        }
                    }
                }
                return prospetto;
            }),
            tap(prosp => {
                this._prospetto.next(prosp);
            })
        );
    }

    presaVisioneProspetto(giorno: string, visione: string){
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
            this.utente = localStorage.getItem('utente');
        }else{
            this.authService.username.subscribe(username => {
                this.utente = username;
            });
        }

        this.datiService.sezione.subscribe(cod => {
            this.sezione_dati = cod;
        });

        if (this.sezione_dati.toLowerCase().includes('firenze')) {
            this.sezione = 'FI';
        }
        if (this.sezione_dati.toLowerCase().includes('livorno') || this.sezione_dati.toLowerCase().includes('elba') || this.sezione_dati.toLowerCase().includes('svs')) {
            this.sezione = 'LI';
        }
        if (this.sezione_dati.toLowerCase().includes('massa')) {
            this.sezione = 'MS';
        }
        if (this.sezione_dati.toLowerCase().includes('pisa')) {
            this.sezione = 'PI';
        }

        if (Capacitor.isPluginAvailable('App')) {
            App.getInfo().then(info => {
                this.appVersion = info.version;
                return this.appVersion;
            },
                err => {
                    this.appVersion = 'web';
                });
        } else {
            this.appVersion = 'web';
        }

        this.codice = parseInt(localStorage.getItem('ID'));

        return this.http
            .get<{ [key: number]: ProspettoData }>(
                `https://demo.trasportosangueorgani.it/ws/prospetto.php?username=${this.utente}&sede=${this.sezione}&versione=${this.appVersion}&codice=${this.codice}&data=${giorno}&visualizzato=${visione}`,
            { headers: { skip: 'true' } }
            ).pipe(
                map(resData => {
                    //console.log(resData);
                    const prospetto = [];
                    for(const key in resData){
                        const missioni = [];
                        if(resData.hasOwnProperty(key)){
                            for(const index in resData[key].missioni){
                                let anno = resData[key].missioni[index].data;
                                let mese = resData[key].missioni[index].data;
                                let giorno = resData[key].missioni[index].data;
                                anno = anno.substr(0, 4);
                                mese = mese.substr(4, 2);
                                giorno = giorno.substr(6, 2);
                                let date = anno.toString() + '-' + mese.toString() + '-' + giorno.toString();
                                missioni.push(new ProspettoMissioni(
                                    resData[key].missioni[index].viaggio,
                                    new Date(date),
                                    resData[key].missioni[index].ora,
                                    resData[key].missioni[index].pd1,
                                    resData[key].missioni[index].pd2,
                                    resData[key].missioni[index].pd3,
                                    resData[key].missioni[index].mezzo,
                                    0,
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    '',
                                    0,
                                    '',
                                    0,
                                    0,
                                    '',
                                    0,
                                    resData[key].missioni[index].stato
                                ));
                            }
    
                            if(resData[0].success === true){
                                prospetto.push(
                                    new Prospetto(
                                        resData[key].targa,
                                        missioni,
                                        resData[key].success,
                                        resData[key].message
                                    )
                                );
                            }else{
                                prospetto.push(
                                    new Prospetto(
                                        "",
                                        [],
                                        resData[key].success,
                                        resData[key].message
                                    )
                                );
                            }
                        }
                    }
                    return prospetto;
                }),
                tap(prosp => {
                    this._prospetto.next(prosp);
                })
            );
    }
}
