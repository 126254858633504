import { environment } from './../environments/environment';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { HttpClient } from '@angular/common/http';
import { filter, switchMap, catchError, take, map } from 'rxjs/operators';
import { MissioniService } from './missioni/missioni.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Component, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer, of } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ToastController, Platform, MenuController, AlertController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token} from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { SqliteService } from './sqlite.service';
import { DetailService } from './detail.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

// import { FCM } from '@ionic-native/fcm/ngx';
//import { ForegroundService } from '@ionic-native/foreground-service/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  private authSub: Subscription;
  private status: ConnectionStatus;
  private listener: any;
  private numMissioni: number = 0;
  private countMissioni: number;
  private id_notification = 0;
  private read_missioni = false;
  private token_fcm : string = '';

  InfoApp:{ };
  appVersion: any;
  appName: any;
  ok_sanificazione = false;
  ok_richieste = false;
  show_logout = false;

  @Output() data: any;

  intervalPeriod: number;

  minutes: number;
  subscription: Subscription;

  subscription_temp: Subscription;
  period_temp: number;

  // Handle Update Row Operation
  updateActive: boolean;
  to_update_item: any;

  private initPlugin: boolean;
  public isWeb: boolean = false;
  
  constructor( 
      private router: Router, 
      private authService: AuthService, 
      private toastCtrl: ToastController, 
      private backgroundMode: BackgroundMode, 
      private platform: Platform, 
      private missionService: MissioniService, 
      private http: HttpClient,
      private sqliteService: SqliteService,
      private detailService: DetailService,
      private permissions: AndroidPermissions,
      private alertCtrl: AlertController
      // private fcm: FCM
    ) {


      this.initializeApp();
    }

    initializeApp() {

      localStorage.setItem('getdatioffline','0');

      /* this.backgroundMode.configure({resume:true});
      this.backgroundMode.setEnabled(true);  */
      
      if( !localStorage.getItem('show_alert') || localStorage.getItem('show_alert') === '0'){
        this.showAlert();
      }
      
      if( !this.platform.is('desktop') )
      {
        /* this.backgroundMode.setEnabled(true); 

        this.backgroundMode.setDefaults({
          title: 'EasyOBL',
          text: 'App attiva in background.',
          icon: 'icon_local_notifications',
          color: 'FFFFFF',
          resume: true,
          hidden: false,
          bigText: false,
          silent: false
        }); */
      }  
      /*this.backgroundMode.configure({
        title: 'EasyOBL',
        text: 'App attiva in background.',
        icon: 'icon_local_notifications',
        color: 'FFFFFF',
        resume: true,
        hidden: false,
        silent: false
      });*/

      if(!this.platform.is('desktop')){

        LocalNotifications.createChannel({
          id: 'LocalNotificationAlerts',
          name: 'LocalNotificationAlerts',
          importance: 5,
          description: 'Local Notification Alerts',
          sound: 'sirena_ambulanza.wav',
          visibility: 1,
          vibration: true,
          });
      }
        
      //MONICA TODO
      /* this.platform.ready().then(async () => {
        this.sqliteService.initializePlugin().then(ret => {
          this.initPlugin = ret;
          console.log('>>>> in App  this.initPlugin ' + this.initPlugin);
          // this.createDbTable();

          const p: string = this.sqliteService.platform;
          console.log(`plaform ${p}`);
            
            if(p === "web") {
              console.log('ramo web');
              this.isWeb = true;
              // await customElements.whenDefined('jeep-sqlite');
              // const jeepSqliteEl = document.querySelector('jeep-sqlite');
              // if(jeepSqliteEl != null) {
              await this.sqliteService.initWebStore(); 
    
              /* console.log(`isStoreOpen ${await jeepSqliteEl.isStoreOpen()}`)
                console.log(`$$ jeepSqliteEl is defined}`)*/
              /* } else {
                console.log('$$ jeepSqliteEl is null');
              } */
      /*       }

            try {
              console.log(`going to create a connection`)
              const db = await this.sqliteService.createConnection("missioni",false,"no-encryption", 1);
              let res: any;
              let query: string;
              console.log(`db ${JSON.stringify(db)}`)
              await db.open();
              console.log(`after db.open`)

              query = `DROP TABLE ts_richieste_testata`;
              console.log(`drop table ts_richieste_testata ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)

              query = `DROP TABLE ts_richieste_prodotti`;
              console.log(`drop table ts_richieste_prodotti ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)

              query = `DROP TABLE ts_richieste_prenotazioni`;
              console.log(`drop table ts_richieste_prenotazioni ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)

              query = `DROP TABLE ts_richieste_temperature`;
              console.log(`drop table ts_richieste_prenotazioni ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)

              query = `CREATE TABLE IF NOT EXISTS testata (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,causale TEXT NOT NULL,causale_descr TEXT NOT NULL,id_tipomezzo INTEGER NOT NULL,pdi_partenza REAL NOT NULL,pdi_destinazione REAL NOT NULL,id_automezzo TEXT NOT NULL,id_assegnazione TEXT NOT NULL,descrizione TEXT NOT NULL PRIMARY KEY (esercizio,registro,numero ));`
              console.log(`query testata ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)

              /* query = `CREATE TABLE IF NOT EXISTS richieste_prodotti (esercizio TEXT NOT NULL,registro TEXT NOT NULL, numero REAL NOT NULL,numero_riga INTEGER NOT NULL,itinerario_riga INTEGER NOT NULL,stati_riga INTEGER NOT NULL,id_stato INTEGER NULL,id_prodotto TEXT NULL,datiextra_prodotto TEXT NULL,id_contenitore TEXT NULL,id_cella TEXT NULL,id_sigillo TEXT NULL,data_comunicazione TEXT NULL,ora_comunicazione TEXT NULL,latlon TEXT NULL,dataaggiornamento TEXT NULL,utente TEXT NULL, PRIMARY KEY (esercizio,registro,numero,numero_riga,itinerario_riga,stati_riga));`
              console.log(`query richieste_prodotti ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)  */ 
              
      /*         query = `CREATE TABLE IF NOT EXISTS prenotazioni (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga INTEGER NOT NULL,data_viaggio TEXT NOT NULL,ora_viaggio TEXT NOT NULL,id_automezzo TEXT NOT NULL,stato_richiesta INTEGER NOT NULL,id_assegnazione TEXT NOT NULL,PRIMARY KEY  (esercizio,registro,numero,numero_riga));`
              console.log(`query prenotazioni ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)
              
              query = `CREATE TABLE IF NOT EXISTS itinerari (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL,tipo_operazione INTEGER NOT NULL,orario_arrivo TEXT NOT NULL,orario_partenza TEXT NOT NULL,pdi_lat TEXT NULL,pdi_lng TEXT NULL,pdi_descr1 TEXT NULL,pdi_descr2 TEXT NULL,pdi_descr3 TXT NULL,pdi_codice INTEGER NOT NULL,quantita INTEGER NOT NULL DEFAULT 0, articolo_barcode TEXT NOT NULL,articolo_descrizione TEXT NOT NULL,articolo_codice INTEGER NOT NULL,  PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, tipo_operazione));`
              console.log(`query itinerari ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`) 
              
              query = `CREATE TABLE IF NOT EXISTS temperature (id_assegnazione TEXT NOT NULL,id_contenitore TEXT NOT NULL,data TEXT NOT NULL,temperatura TEXT NOT NULL,PRIMARY KEY (id_assegnazione,id_contenitore,data,temperatura ));`
              console.log(`query ts_richieste_temperature ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)        
              
              query = `CREATE TABLE IF NOT EXISTS rest_service_fifo (url_metodo TEXT NOT NULL,type TEXT NOT NULL,post_params TEXT NOT NULL,data TEXT NOT NULL,ora TEXT NOT NULL,PRIMARY KEY (url_metodo,type,post_params,data,ora));`
              console.log(`query rest_service_fifo ${query}`)
              res = await db.execute(query);
              console.log(`res: ${JSON.stringify(res)}`)        /*             
              
             /*  query=`INSERT INTO rest_service_fifo (url_metodo,type,post_params,data,ora) VALUES( 'htttp','post','{}','20211130','16.39')`;
              console.log('query insert rest_service_fifo', query);
              res = await db.execute(query);
              console.log(`res della insert rest_service_fifo: ${JSON.stringify(res)}`)
 */
            /*  query = `SELECT * FROM rest_service_fifo`;
              console.log('query select rest_service_fifo', query);
              res = await db.query(query);
              console.log(`res della select rest_service_fifo: ${JSON.stringify(res)}`)

              await db.close();
              console.log(`after db.close`)
            } catch (err) {
              console.log(`Error: ${err}`);
              this.initPlugin = false;
            }
    
            console.log(">>>> in App  this.initPlugin " + this.initPlugin)
          });

        });
      }); */

     }
  
  setShowLogout(result: any) {
    //access result here
    this.show_logout = result;
  }

  setShowMaterialeSanificazione(result: any) {
    //access result here
    let azienda = localStorage.getItem('azienda');
    if(azienda === 'svs')
    {
      if(this.authService.utente.toLowerCase() === 'fcarnovale' || this.authService.utente.toLowerCase() === 'mcolini' 
            || this.authService.utente.toLowerCase() === 'fmalara' || this.authService.utente.toLowerCase() === 'scasentini')
      {
        this.ok_sanificazione = true;
      }
    }
    else
    {
      this.ok_sanificazione = false;
    }
  }

  setShowMateriale(result: any) {
    //access result here
    let azienda = localStorage.getItem('azienda');
    console.log('azienda setShowMateriale', azienda);
    if( azienda === 'svs' )
    {
      this.ok_richieste = true;
    }        
    else{
      this.ok_richieste = false;
    }
  }

  /*startService() {
    // Notification importance is optional, the default is 1 - Low (no sound or vibration)
    this.foregroundService.start('EsayOBL', 'Rilevamento Temperature Contenitori',);
   }*/

   /*stopService() {
    // Disable the foreground service
    this.foregroundService.stop();
   }  */

  ngOnInit() {
    console.log('ngoninit');

    localStorage.setItem('readMissioni', '0'); 
    

    //MONICA TODO
    /* if( this.platform.is('desktop')){
      console.log('remove targhe da localstorage');
      localStorage.removeItem('targhe');
    } */

/*     if( !this.platform.is('desktop') )
    {

    if(!this.platform.is('desktop')){

      PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('Push registration success, token: ',token.value);
          this.token_fcm = token.value;
          // this.presentToast("Push registration success" + token.value);
          this.saveFcmToken(token.value);
        }  
      );    

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ',JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
        let title = notification.title;
        let body = notification.body;
          console.log('Push received: ',JSON.stringify(notification));
          this.id_notification++;
          LocalNotifications.schedule({
            notifications:[
              {
                  title : title,
                  body : body,
                  id : this.id_notification,
                  smallIcon: 'obl_icona_18',
                  //sound: 'suono_notification.wav',
                  iconColor: '#7cc5f2',
                  channelId: 'LocalNotificationAlerts',
              }
            ]
          });
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ',JSON.stringify(notification));
        }
      );
    } */
    
    //Scansione datalogger
    //Lettura da localstorage di "gestionemissioni"; TODO MONICA
    // this.startService();


    //MONICA TODO
    /* if( !this.platform.is('desktop') )
    {

    if( !this.platform.is('desktop') ){

      console.log('loop per scansione temperatura');
      this.period_temp = 60 * 1000;

      let devices = ['D0:23:51:9E:45:D3','EF:13:13:D4:49:5B','F7:B2:F2:A4:C2:9B','DA:75:8E:91:39:BF','F5:96:D6:AC:85:94','D6:81:0D:1A:AC:E0'];
      devices.forEach(id_device => {
        
        this.subscription_temp = timer(0, this.period_temp)
        .pipe(
          switchMap(() => {
            console.log('scan temperatura');
            
            //Richiamiamo la scansione sono se ci sono missioni attive  TODO MONICA

            return this.missionService.scan(id_device)
          }),
          filter(data => data !== undefined)
        )
        .subscribe( data => {
            console.log('data', data);
        });
      });
    } */

    // this.stopService();

    this.authService.obLogout.subscribe((result) => {
      this.setShowLogout(result);
    });
 
    this.authService.obMaterialeSanificazione.subscribe((result) => {
      this.setShowMaterialeSanificazione(result);
    });

    this.authService.obMateriale.subscribe((result) => {
      this.setShowMateriale(result);
    });

    if(Capacitor.isPluginAvailable('Network')){
      this.getStatus();
      this.startListenNetwork();
    }
      
    if (Capacitor.isPluginAvailable('App')) {
      App.getInfo().then( info => {
        this.appVersion = info.version;
        return this.appVersion;
      },
      err => {
        this.appVersion = 'web';
      });
    }else{
      this.appVersion = 'web';
    }

    if (Capacitor.isPluginAvailable('App')) {
      App.getInfo().then( info => {
        this.appName = info.name;
        return this.appName;
      },
      err => {
        this.appName = 'OBL';
      });
    }else{
      this.appName = 'OBL';
    }

    if( this.authService.userIsAuthenticated )
      this.show_logout = true;
    else
      this.show_logout = false;  
    
    if( this.authService.userIsAuthenticated 
        && ( this.authService.utente.toLowerCase() === 'fcarnovale' || this.authService.utente.toLowerCase() === 'mcolini' 
            || this.authService.utente.toLowerCase() === 'fmalara' || this.authService.utente.toLowerCase() === 'scasentini' ) )
    {
      this.ok_sanificazione = true;
    } 
    else{
      this.ok_sanificazione = false;
    }
    
    if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null 
        && ( localStorage.getItem('utente').toLowerCase() === 'fcarnovale' || localStorage.getItem('utente').toLowerCase() === 'mcolini' 
            || localStorage.getItem('utente').toLowerCase() === 'fmalara' || localStorage.getItem('utente').toLowerCase() === 'scasentini') ){
      this.ok_sanificazione = true;
    }
    else{
      this.ok_sanificazione = false;
    }

    if( localStorage.getItem('azienda') === 'svs'){
      this.ok_richieste = true;
    }
    else{
      this.ok_richieste = false;
    }

    //this.createDbTable();
    
  }

   ionViewDidEnter() {
     //MONICA TODO
    /* if( this.platform.is('desktop')){
      console.log('remove targhe da localstorage');
      localStorage.removeItem('targhe');
    } */

    this.authService.obLogout.subscribe((result) => {
      this.setShowLogout(result);
    });
 
    this.authService.obMaterialeSanificazione.subscribe((result) => {
      this.setShowMaterialeSanificazione(result);
    });

    this.authService.obMateriale.subscribe((result) => {
      this.setShowMateriale(result);
    });

    if (Capacitor.isPluginAvailable('Network')) {
      this.getStatus();
      this.startListenNetwork();
    }

    if (Capacitor.isPluginAvailable('App')) {
      App.getInfo().then( info => {
        this.appVersion = info.version;
        return this.appVersion;
      },
      err => {
        this.appVersion = 'web';
      });
    } else {
      this.appVersion = 'web';
    }

    if (Capacitor.isPluginAvailable('App')) {
      App.getInfo().then( info => {
        this.appName = info.name;
        return this.appName;
      },
      err => {
        this.appName = 'OBL';
      });
    }else{
      this.appName = 'OBL';
    }

    if( this.authService.userIsAuthenticated )
      this.show_logout = true;
    else
      this.show_logout = false;  

    if( this.authService.userIsAuthenticated 
      && ( this.authService.utente.toLowerCase() === 'fcarnovale' || this.authService.utente.toLowerCase() === 'mcolini' 
          || this.authService.utente.toLowerCase() === 'fmalara' || this.authService.utente.toLowerCase() === 'scasentini' ) )
    {
      this.ok_sanificazione = true;
    } 

    if( localStorage.getItem('azienda') === 'svs'){
      console.log('check azienda');
      this.ok_richieste = true;
    }
    else{
      this.ok_richieste = false;
    }

    //this.createDbTable();
  }

  async createDbTable(){
    let db : SQLiteDBConnection;
    let res: any;
    let res2: any;
    let res3: any;
    let res4: any;
    let res5: any;
    let res6: any;
    let res7: any;
    let res8: any;
    let res9: any;
    let res10: any;
    let res11: any;
    let res12: any;
    let res13: any;
    let res14: any;
    let res15: any;
    let query: string;

    try {
      console.log(`going to create a connection`)
      db = await this.sqliteService.createConnection("missioni",false,"no-encryption", 1);
      console.log(`db ${JSON.stringify(db)}`)
      await db.open();
      console.log(`after db.open`)

      // db = await this.missionService.SetConnection();

      query = `CREATE TABLE IF NOT EXISTS testata (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,causale TEXT NOT NULL,causale_descr TEXT NOT NULL,id_tipomezzo INTEGER NOT NULL,pdi_partenza REAL NOT NULL,pdi_destinazione REAL NOT NULL,id_automezzo TEXT NOT NULL,id_assegnazione TEXT NOT NULL,descrizione TEXT NOT NULL, PRIMARY KEY (esercizio,registro,numero ));`
      console.log(`query testata ${query}`)
      res = await db.execute(query);
      console.log(`res: ${JSON.stringify(res)}`)
      
      query = `CREATE TABLE IF NOT EXISTS prenotazioni (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga INTEGER NOT NULL,data_viaggio TEXT NOT NULL,ora_viaggio TEXT NOT NULL,id_automezzo TEXT NOT NULL,stato_richiesta INTEGER NOT NULL,id_assegnazione TEXT NOT NULL,PRIMARY KEY  (esercizio,registro,numero,numero_riga));`
      console.log(`query prenotazioni ${query}`)
      res2 = await db.execute(query);
      console.log(`res2: ${JSON.stringify(res2)}`)
      
      query = `CREATE TABLE IF NOT EXISTS itinerari (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL,tipo_operazione INTEGER NOT NULL,orario_arrivo TEXT NOT NULL,orario_partenza TEXT NOT NULL,pdi_lat TEXT NULL,pdi_lng TEXT NULL,pdi_descr1 TEXT NULL,pdi_descr2 TEXT NULL,pdi_descr3 TXT NULL,pdi_codice INTEGER NOT NULL,quantita INTEGER NOT NULL DEFAULT 0, articolo_barcode TEXT NOT NULL,articolo_descrizione TEXT NOT NULL,articolo_codice INTEGER NOT NULL, ordine INTEGER NOT NULL,  PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, tipo_operazione));`
      console.log(`query itinerari ${query}`)
      res3 = await db.execute(query);
      console.log(`res3: ${JSON.stringify(res3)}`)

      query = `CREATE TABLE IF NOT EXISTS testata_prospetto (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,causale TEXT NOT NULL,causale_descr TEXT NOT NULL,id_tipomezzo INTEGER NOT NULL,pdi_partenza REAL NOT NULL,pdi_destinazione REAL NOT NULL,id_automezzo TEXT NOT NULL,id_assegnazione TEXT NOT NULL,descrizione TEXT NOT NULL, PRIMARY KEY (esercizio,registro,numero ));`
      console.log(`query testata ${query}`)
      res4 = await db.execute(query);
      console.log(`res4: ${JSON.stringify(res4)}`)
      
      query = `CREATE TABLE IF NOT EXISTS prenotazioni_prospetto (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga INTEGER NOT NULL,data_viaggio TEXT NOT NULL,ora_viaggio TEXT NOT NULL,id_automezzo TEXT NOT NULL,stato_richiesta INTEGER NOT NULL,id_assegnazione TEXT NOT NULL,PRIMARY KEY  (esercizio,registro,numero,numero_riga));`
      console.log(`query prenotazioni ${query}`)
      res5 = await db.execute(query);
      console.log(`res5: ${JSON.stringify(res5)}`)

      query = `CREATE TABLE IF NOT EXISTS itinerari_prospetto (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL,tipo_operazione INTEGER NOT NULL,orario_arrivo TEXT NOT NULL,orario_partenza TEXT NOT NULL,pdi_lat TEXT NULL,pdi_lng TEXT NULL,pdi_descr1 TEXT NULL,pdi_descr2 TEXT NULL,pdi_descr3 TXT NULL,pdi_codice INTEGER NOT NULL,quantita INTEGER NOT NULL DEFAULT 0, articolo_barcode TEXT NOT NULL,articolo_descrizione TEXT NOT NULL,articolo_codice INTEGER NOT NULL, ordine INTEGER NOT NULL,  PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, tipo_operazione));`
      console.log(`query itinerari ${query}`)
      res6 = await db.execute(query);
      console.log(`res6: ${JSON.stringify(res6)}`) 

      query = `CREATE TABLE IF NOT EXISTS richiesta_prodotti (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL, numero_riga_missione INTEGER NOT NULL, numero_riga_stato INTEGER NOT NULL, stato INTEGER NOT NULL, id_prodotto TEXT NULL, id_contenitore TEXT NULL, id_cella TEXT NULL, temperatura TEXT NULL, tipo_operazione INTEGER NOT NULL, data_comunicazione TEXT NOT NULL, ora_comunicazione TEXT NOT NULL, lat TEXT NULL, lng TEXT NULL, ordine INTEGER NOT NULL, PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, numero_riga_missione, numero_riga_stato, tipo_operazione));`
      console.log(`query itinerari ${query}`)
      res7 = await db.execute(query);
      console.log(`res7: ${JSON.stringify(res7)}`)
      
      query = `CREATE TABLE IF NOT EXISTS temperature (id_assegnazione TEXT NOT NULL,id_contenitore TEXT NOT NULL,data TEXT NOT NULL,temperatura TEXT NOT NULL,PRIMARY KEY (id_assegnazione,id_contenitore,data,temperatura ));`
      console.log(`query temperature ${query}`)
      res8 = await db.execute(query);
      console.log(`res8: ${JSON.stringify(res8)}`)
     
      query = `CREATE TABLE IF NOT EXISTS rest_service_fifo (url_metodo TEXT NOT NULL,type TEXT NOT NULL,params TEXT NOT NULL, headers TEXT NULL, data TEXT NOT NULL,ora TEXT NOT NULL,PRIMARY KEY (url_metodo,type,params,data,ora));`
      console.log(`query rest_service_fifo ${query}`)
      res9 = await db.execute(query);
      console.log(`res9: ${JSON.stringify(res9)}`)

      //Le delete ora vanno qua per la fase di test, poi andranno spostate nella destroy della app TODO

      query =`DELETE FROM testata;`;
      console.log('query delete testata', query);
      res10 = await db.execute(query);
      console.log(`res10 della delete testata: ${JSON.stringify(res10)}`)

      query =`DELETE FROM prenotazioni;`;
      console.log('query delete prenotazioni', query);
      res11 = await db.execute(query);
      console.log(`res11 della delete prenotazioni: ${JSON.stringify(res11)}`);

      query =`DELETE FROM itinerari;`;
      console.log('query delete itinerari', query);
      res12 = await db.execute(query);
      console.log(`res12 della delete itinerari: ${JSON.stringify(res12)}`)

      query =`DELETE FROM richiesta_prodotti;`;
      console.log('query delete richiesta_prodotti', query);
      res13 = await db.execute(query);
      console.log(`res13 della delete richiesta_prodotti: ${JSON.stringify(res13)}`)

      query =`DELETE FROM testata_prospetto;`;
      console.log('query delete testata_prospetto', query);
      res13 = await db.execute(query);
      console.log(`res13 della delete testata_prospetto: ${JSON.stringify(res13)}`)

      query =`DELETE FROM prenotazioni_prospetto;`;
      console.log('query delete prenotazioni_prospetto', query);
      res14 = await db.execute(query);
      console.log(`res14 della delete prenotazioni_prospetto: ${JSON.stringify(res14)}`)

      query =`DELETE FROM itinerari_prospetto;`;
      console.log('query delete itinerari_prospetto', query);
      res15 = await db.execute(query);
      console.log(`res15 della delete itinerari_prospetto: ${JSON.stringify(res15)}`)

      // await db.close();
      await this.sqliteService.closeConnection('missioni');
      console.log('after connection close');
    } catch (err) {
      console.log(`Error: ${err}`);
      this.initPlugin = false;
    } 
  }

  saveFcmToken(push_token:string)
  {
    console.log('token_fcm', push_token)
    
    if( push_token !== '' && localStorage.getItem('networkok') && localStorage.getItem('networkok') === '1' )
    {
      let id = localStorage.getItem('ID');
      //salvataggio del token nella tabella ANAGRAFE_FCMTOKEN
      console.log('chiamata',`https://demo.trasportosangueorgani.it/ws/fcm.php?id=${id}&token=${push_token}`);
      return this.http
        .get<any>(
          `https://demo.trasportosangueorgani.it/ws/fcm.php?id=${id}&token=${push_token}`
        ).
        subscribe( resData => console.log('resdata save fcmtoken',JSON.stringify(resData)))
    }
  }
 
  async getStatus() {
    try {
      this.status = await Network.getStatus();
    } catch (e) { console.log("Error", e) }
  }

  startListenNetwork() {
    this.listener = Network.addListener('networkStatusChange', (status) => {
      if (!status.connected) {
        localStorage.setItem('networkok','0');
        this.presentToast("La tua connessione Internet sembra essere offline. Controlla la connessione prima di utilizzare la app.");
      }
      else
      {
        localStorage.setItem('networkok','1');
        window.location.reload();

        //Al momento che torna la connessione eseguiamo le chiamate salvate nella fifo locale
        // this.missioniService.runFifoService();
        // this.presentToast("Connession ok!");
      }
    });
  }

  stopListenNetwork() {
    if (this.listener) this.listener.remove();
  }

  async presentToast(msg) {
    let toast = await this.toastCtrl.create({ message: msg, duration: 3000, position: 'bottom' });

    toast.present();
  }

  onLogout() {
    this.authService.logout();
    this.show_logout = false; 
    this.ok_sanificazione = false;
    this.ok_richieste = false;
        
    this.router.navigateByUrl('/auth');
  }

  ionViewWillLeave() {
    if (Capacitor.isPluginAvailable('Network')) {
      this.stopListenNetwork();
    }
    this.show_logout = false; 
  }

  ngOnDestroy() {
    if (Capacitor.isPluginAvailable('Network')) {
      this.stopListenNetwork();
    }

    if (!this.authSub) {
      this.authSub.unsubscribe();
    }
    this.show_logout = false; 
    //this.stopService();
  }

  private showAlert() {
    this.alertCtrl
      .create({
        header: 'Avviso',
        message: 'EasyOBL utilizza la localizzazione in background per migliorare le funzionalità di tracciamento nei prelievi e consegna dei materiali. La localizzazione è attiva anche quando EasyOBL non è in uso.',
        buttons: [
         {
           text: 'Ok',
           handler: () => {
            if (Capacitor.isPluginAvailable('Geolocation')) {
              this.permissions.checkPermission(this.permissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
                result => console.log('Has permission?', result.hasPermission)
              );
              
              this.permissions.requestPermissions([this.permissions.PERMISSION.ACCESS_COARSE_LOCATION, this.permissions.PERMISSION.ACCESS_FINE_LOCATION]);
            }
            localStorage.setItem('show_alert', '1');
           }  
         } 
        ],
      })
      .then(alertEl => alertEl.present());
  }

}

